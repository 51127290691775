import Moment from 'react-moment';

const Group = ({ groups, toggleUnavailable }) => {
    return (
        <>
            {groups.map((group) => (
                <div className={`group ${group.id === 0 ? 'ungroupped' : ''}`} key={group.id}>
                    <div className="groupName" >{group.name}</div>
                    <div className="members">
                        <i className="ri-group-line groupIcon"></i>
                        <div className="memberCount">{group.count}</div>
                    </div>
                    {group.id === 0 ? '' : 
                    <div className="lastUpdated">
                        <i className="ri-history-line updatedIcon"></i>
                        <div className="updatedLabel">
                            <div className="updatedBy">{group.lastModifiedBy}</div>
                            <Moment className="updatedDate" date={group.lastModified} format='MMM Do YYYY' />
                        </div>
                    </div>
                    }
                    <div className="actions">
                        {group.id === 0 ?
                            <button className='textbtn small neutral' style={{opacity: "100% !important"}} onClick={() => toggleUnavailable()}><div className='label'>View all</div></button>
                            :
                            <>
                                <button onClick={() => toggleUnavailable()} className="iconbtn brand">
                                    <div className="container">
                                        <i className="ri-user-add-line neutral" style={{ fontSize: "24px" }}></i>
                                    </div>
                                </button>
                                <button onClick={() => toggleUnavailable()} className="iconbtn brand">
                                    <div className="container">
                                        <i className="ri-edit-line neutral" style={{ fontSize: "24px" }}></i>
                                    </div>
                                </button>
                                <button onClick={() => toggleUnavailable()} className="iconbtn brand">
                                    <div className="container">
                                        <i className="ri-send-plane-line brand" style={{ fontSize: "24px" }}></i>
                                    </div>
                                </button>
                            </>
                        }

                    </div>

                </div>
            ))}

        </>
    )
}

export default Group
