import { useState, useEffect } from 'react';
import Address from './Address';
import FixedActions from './FixedActions';

const Addresses = ({ sideMenuBlur, toggleUnavailable }) => {

  const [addresses, setAddresses] = useState([])
  const [selectedList, setSelectedList] = useState([])

  useEffect(() => {
    const getAddresses = async () => {
      const addressesFromServer = await fetchAddresses()
      setAddresses(addressesFromServer);
    }
    getAddresses();
  }, [])

  const fetchAddresses = async () => {
    const res = await fetch('https://cmcserver.onrender.com/addresses/')
    const data = await res.json()
    return data
  }

  // useEffect(() => {
  //   const getGroups = async () => {
  //     const groupsFromServer = await fetchGroups()

  //     setGroups(groupsFromServer);
  //   }
  //   getGroups();
  // }, [])

  // const fetchGroups = async () => {
  //   const res = await fetch('https://cmcserver.onrender.com/groups/')
  //   const data = await res.json()
  //   return data
  // }

  const selectedLister = id => {
    let newAddresses = addresses.map(address =>
      address.id === id
        ? {
          ...address,
          selected: !address.selected,
        }
        : {
          ...address,
        }
    );

    // Add the address to the selected list if it is checked.
    if (newAddresses.find(address => address.id === id).selected) {
      setSelectedList([...selectedList, addresses.find(address => address.id === id)]);
    } else {
      // Remove the address from the selected list if it is unchecked.
      setSelectedList(selectedList.filter(address => address.id !== id));
    }

    setAddresses(newAddresses);
  };

  const onAddressCheckbox = id => {
    selectedLister(id);
  };


  return (
    <div className={`addressContainer ${sideMenuBlur ? 'blur' : ''}`}>
      <FixedActions toggleUnavailable={toggleUnavailable} />
      <div className='pageTitleContainer'>
        <div className="pageTitleSearch">
          <div className='pageTitle'>Addresses</div>
          <div className="textInputContainer">
            <i className="textInputIcon ri-search-2-line"></i>
            <input className="textInput search" type="text" name="name" placeholder='Search address' onClick={() => toggleUnavailable()} />
          </div>
        </div>
        <button className='filledbtn largeFilledBtn neutralFilledBtn iconLeft' onClick={() => toggleUnavailable()}><i className="ri-user-add-line icon"></i> <div className='label'>Add address</div></button>
      </div>
      <div className='filterPaginationAddresses'>
        <div className="filterPagination">
          <div className="filter">
            <div className="filterItem" onClick={() => toggleUnavailable()}>
              <div className="filterItemLabel">Groups</div>
            </div>
            <div className="filterItem" onClick={() => toggleUnavailable()}>
              <div className="filterItemLabel">Last created</div>
            </div>
          </div>
          <div className="pagination">
            {addresses.length} addresses
          </div>
        </div>
        <div className="addressList">
          {selectedList.length > 0 ?
            <div className="addressesHeader" style={{ position: "sticky", top: "0", background: "white" }}>
              <div className="selected">{selectedList.length} address(s) selected</div>
              <button className='strokeBtn smallStrokeBtn neutralStrokeBtn noIcon' onClick={() => toggleUnavailable()}><div className='label'>Add to group</div></button>
              <button className='filledbtn smallFilledBtn neutralFilledBtn noIcon' onClick={() => toggleUnavailable()}><div className='label'>Send tokens</div></button>
            </div>

            :
            <div className="addressesHeader">
              <div className="name">Name</div>
              <div className="walletAddress">Address</div>
              <div className="lastInteracted">Last interacted</div>
              <div className="groups">Groups</div>
            </div>
          }
          <Address addresses={addresses} onAddressCheckbox={onAddressCheckbox} toggleUnavailable={toggleUnavailable} />
        </div>
      </div>
      <div className="footerPagination">
        <div className="pagination">
          {addresses.length} addresses
        </div>
      </div>

    </div>
  )
}

export default Addresses
