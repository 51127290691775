import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Proposal from "./Proposal"
import FixedActions from './FixedActions';


const Proposals = ({ sideMenuBlur, toggleUnavailable }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const tab = queryParams.get('tab')

  const [needsApproval, setNeedsApproval] = useState([])
  const [needsApprovalCount, setNeedsApprovalCount] = useState()
  const [needsApprovalPage, setNeedsApprovalPage] = useState(0)
  const [needsApprovalPerPage, setNeedsApprovalPerPage] = useState(25)

  const [readyToExecute, setReadyToExecute] = useState([])
  const [readyToExecuteCount, setReadyToExecuteCount] = useState()
  const [readyToExecutePage, setReadyToExecutePage] = useState(0)
  const [readyToExecutePerPage, setReadyToExecutePerPage] = useState(25)

  const [discarded, setDiscarded] = useState([])
  const [discardedCount, setDiscardedCount] = useState()
  const [discardedPage, setDiscardedPage] = useState(0)
  const [discardedPerPage, setDiscardedPerPage] = useState(25)


  const [lastIndexNeedsApproval, setLastIndexNeedsApproval] = useState((needsApprovalPage + 1) * needsApprovalPerPage)
  const [firstIndexNeedsApproval, setFirstIndexNeedsApproval] = useState(lastIndexNeedsApproval - needsApprovalPerPage + 1)
  const [lastPageNeedsApproval, setLastPageNeedsApproval] = useState(false)
  const [firstPageNeedsApproval, setFirstPageNeedsApproval] = useState(true)


  const [lastIndexReadyToExecute, setLastIndexReadyToExecute] = useState((readyToExecutePage + 1) * readyToExecutePerPage)
  const [firstIndexReadyToExecute, setFirstIndexReadyToExecute] = useState(lastIndexReadyToExecute - readyToExecutePerPage + 1)
  const [lastPageReadyToExecute, setLastPageReadyToExecute] = useState(false)
  const [firstPageReadyToExecute, setFirstPageReadyToExecute] = useState(true)


  const [lastIndexDiscarded, setLastIndexDiscarded] = useState((discardedPage + 1) * discardedPerPage)
  const [firstIndexDiscarded, setFirstIndexDiscarded] = useState(lastIndexDiscarded - discardedPerPage + 1)
  const [lastPageDiscarded, setLastPageDiscarded] = useState(false)
  const [firstPageDiscarded, setFirstPageDiscarded] = useState(true)



  useEffect(() => {
    const getNeedsApproval = async () => {
      const needsApprovalFromServer = await fetchNeedsApproval()
      setNeedsApproval(needsApprovalFromServer.needsApproval);
      setNeedsApprovalCount(needsApprovalFromServer.needsApprovalCount);
    }
    getNeedsApproval();
  }, [])

  const fetchNeedsApproval = async () => {
    const queryParams = new URLSearchParams(location.search)
    const walletId = queryParams.get('walletId')
    if (walletId) {
      const res = await fetch(`https://cmcserver.onrender.com/proposals/needs-approval/?page=${needsApprovalPage}&walletId=${walletId}`)
      const data = await res.json()
      return data
    }
    const res = await fetch(`https://cmcserver.onrender.com/proposals/needs-approval/?page=${needsApprovalPage}`)
    const data = await res.json()
    return data
  }

  useEffect(() => {
    const getReadyToExecute = async () => {
      const readyToExecuteFromServer = await fetchReadyToExecute()
      setReadyToExecute(readyToExecuteFromServer.readyToExecute);
      setReadyToExecuteCount(readyToExecuteFromServer.readyToExecuteCount);
    }
    getReadyToExecute();
  }, [])

  const fetchReadyToExecute = async () => {
    const queryParams = new URLSearchParams(location.search)
    const walletId = queryParams.get('walletId')
    if (walletId) {
      const res = await fetch(`https://cmcserver.onrender.com/proposals/ready-to-execute/?page=${readyToExecutePage}&walletId=${walletId}`)
      const data = await res.json()
      return data
    }
    const res = await fetch(`https://cmcserver.onrender.com/proposals/ready-to-execute/?page=${readyToExecutePage}`)
    const data = await res.json()
    return data
  }

  useEffect(() => {
    const getDiscarded = async () => {
      const discardedFromServer = await fetchDiscarded()
      setDiscarded(discardedFromServer.discarded);
      setDiscardedCount(discardedFromServer.discardedCount);
    }
    getDiscarded();
  }, [])

  const fetchDiscarded = async () => {
    const queryParams = new URLSearchParams(location.search)
    const walletId = queryParams.get('walletId')
    if (walletId) {
      const res = await fetch(`https://cmcserver.onrender.com/proposals/discarded/?page=${discardedPage}&walletId=${walletId}`)
      const data = await res.json()
      return data
    }
    const res = await fetch(`https://cmcserver.onrender.com/proposals/discarded/?page=${discardedPage}`)
    const data = await res.json()
    return data
  }

  const onNeedsApprovalPageIncrease = () => {
    if (lastIndexNeedsApproval < needsApprovalCount) {
      setNeedsApprovalPage(needsApprovalPage + 1)
      setFirstIndexNeedsApproval(firstIndexNeedsApproval + needsApprovalPerPage)
      setLastIndexNeedsApproval(lastIndexNeedsApproval + needsApprovalPerPage)
      const fetchNeedsApproval = async () => {
        const queryParams = new URLSearchParams(location.search)
        const walletId = queryParams.get('walletId')
        if (walletId) {
          const res = await fetch(`https://cmcserver.onrender.com/proposals/needs-approval/?page=${needsApprovalPage + 1}&walletId=${walletId}`)
          const data = await res.json()
          return data
        }
        const res = await fetch(`https://cmcserver.onrender.com/proposals/needs-approval/?page=${needsApprovalPage + 1}`)
        const data = await res.json()
        return data
      }
      const getNeedsApproval = async () => {
        const needsApprovalFromServer = await fetchNeedsApproval()
        setNeedsApproval(needsApprovalFromServer.needsApproval);
        setNeedsApprovalCount(needsApprovalFromServer.needsApprovalCount);
      }
      getNeedsApproval();
      setFirstPageNeedsApproval(false)
      if (lastIndexNeedsApproval >= (needsApprovalCount - needsApprovalPerPage)) {
        setLastPageNeedsApproval(true)
      } else {
        setLastPageNeedsApproval(false)
      }
    }

  }

  const onNeedsApprovalPageDecrease = () => {
    if (needsApprovalPage > 0) {
      setNeedsApprovalPage(needsApprovalPage - 1)
      setFirstIndexNeedsApproval(firstIndexNeedsApproval - needsApprovalPerPage)
      setLastIndexNeedsApproval(lastIndexNeedsApproval - needsApprovalPerPage)
      const fetchNeedsApproval = async () => {
        const queryParams = new URLSearchParams(location.search)
        const walletId = queryParams.get('walletId')
        if (walletId) {
          const res = await fetch(`https://cmcserver.onrender.com/proposals/needs-approval/?page=${needsApprovalPage - 1}&walletId=${walletId}`)
          const data = await res.json()
          return data
        }
        const res = await fetch(`https://cmcserver.onrender.com/proposals/needs-approval/?page=${needsApprovalPage - 1}`)
        const data = await res.json()
        return data
      }
      const getNeedsApproval = async () => {
        const needsApprovalFromServer = await fetchNeedsApproval()
        setNeedsApproval(needsApprovalFromServer.needsApproval);
        setNeedsApprovalCount(needsApprovalFromServer.needsApprovalCount);
      }
      getNeedsApproval();
      setLastPageNeedsApproval(false)
      if (needsApprovalPage === 1) {
        setFirstPageNeedsApproval(true)
      } else {
        setFirstPageNeedsApproval(false)
      }
    }
  }


  const onReadyToExecutePageIncrease = () => {
    if (lastIndexReadyToExecute < readyToExecuteCount) {
      setReadyToExecutePage(readyToExecutePage + 1)
      setFirstIndexReadyToExecute(firstIndexReadyToExecute + readyToExecutePerPage)
      setLastIndexReadyToExecute(lastIndexReadyToExecute + readyToExecutePerPage)
      const fetchReadyToExecute = async () => {
        const queryParams = new URLSearchParams(location.search)
        const walletId = queryParams.get('walletId')
        if (walletId) {
          const res = await fetch(`https://cmcserver.onrender.com/proposals/ready-to-execute/?page=${readyToExecutePage + 1}&walletId=${walletId}`)
          const data = await res.json()
          return data
        }
        const res = await fetch(`https://cmcserver.onrender.com/proposals/ready-to-execute/?page=${readyToExecutePage + 1}`)
        const data = await res.json()
        return data
      }
      const getReadyToExecute = async () => {
        const readyToExecuteFromServer = await fetchReadyToExecute()
        setReadyToExecute(readyToExecuteFromServer.readyToExecute);
        setReadyToExecuteCount(readyToExecuteFromServer.readyToExecuteCount);
      }
      getReadyToExecute();
      setFirstPageReadyToExecute(false)
      if (lastIndexReadyToExecute >= (readyToExecuteCount - readyToExecutePerPage)) {
        setLastPageReadyToExecute(true)
      } else {
        setLastPageReadyToExecute(false)
      }
    }

  }

  const onReadyToExecutePageDecrease = () => {
    if (readyToExecutePage > 0) {
      setReadyToExecutePage(readyToExecutePage - 1)
      setFirstIndexReadyToExecute(firstIndexReadyToExecute - readyToExecutePerPage)
      setLastIndexReadyToExecute(lastIndexReadyToExecute - readyToExecutePerPage)
      const fetchReadyToExecute = async () => {
        const queryParams = new URLSearchParams(location.search)
        const walletId = queryParams.get('walletId')
        if (walletId) {
          const res = await fetch(`https://cmcserver.onrender.com/proposals/ready-to-execute/?page=${readyToExecutePage - 1}&walletId=${walletId}`)
          const data = await res.json()
          return data
        }
        const res = await fetch(`https://cmcserver.onrender.com/proposals/ready-to-execute/?page=${readyToExecutePage - 1}`)
        const data = await res.json()
        return data
      }
      const getReadyToExecute = async () => {
        const readyToExecuteFromServer = await fetchReadyToExecute()
        setReadyToExecute(readyToExecuteFromServer.readyToExecute);
        setReadyToExecuteCount(readyToExecuteFromServer.readyToExecuteCount);
      }
      getReadyToExecute();
      setLastPageReadyToExecute(false)
      if (readyToExecutePage === 1) {
        setFirstPageReadyToExecute(true)
      } else {
        setFirstPageReadyToExecute(false)
      }
    }
  }

  const onDiscardedPageIncrease = () => {
    if (lastIndexDiscarded < discardedCount) {
      setDiscardedPage(discardedPage + 1)
      setFirstIndexDiscarded(firstIndexDiscarded + discardedPerPage)
      setLastIndexDiscarded(lastIndexDiscarded + discardedPerPage)
      const fetchDiscarded = async () => {
        const queryParams = new URLSearchParams(location.search)
        const walletId = queryParams.get('walletId')
        if (walletId) {
          const res = await fetch(`https://cmcserver.onrender.com/proposals/discarded/?page=${discardedPage + 1}&walletId=${walletId}`)
          const data = await res.json()
          return data
        }
        const res = await fetch(`https://cmcserver.onrender.com/proposals/discarded/?page=${discardedPage + 1}`)
        const data = await res.json()
        return data
      }
      const getDiscarded = async () => {
        const discardedFromServer = await fetchDiscarded()
        setDiscarded(discardedFromServer.discarded);
        setDiscardedCount(discardedFromServer.discardedCount);
      }
      getDiscarded();
      setFirstPageDiscarded(false)
      if (lastIndexDiscarded >= (discardedCount - discardedPerPage)) {
        setLastPageDiscarded(true)
      } else {
        setLastPageDiscarded(false)
      }
    }

  }

  const onDiscardedPageDecrease = () => {
    if (discardedPage > 0) {
      setDiscardedPage(discardedPage - 1)
      setFirstIndexDiscarded(firstIndexDiscarded - discardedPerPage)
      setLastIndexDiscarded(lastIndexDiscarded - discardedPerPage)
      const fetchDiscarded = async () => {
        const queryParams = new URLSearchParams(location.search)
        const walletId = queryParams.get('walletId')
        if (walletId) {
          const res = await fetch(`https://cmcserver.onrender.com/proposals/discarded/?page=${discardedPage - 1}&walletId=${walletId}`)
          const data = await res.json()
          return data
        }
        const res = await fetch(`https://cmcserver.onrender.com/proposals/discarded/?page=${discardedPage - 1}`)
        const data = await res.json()
        return data
      }
      const getDiscarded = async () => {
        const discardedFromServer = await fetchDiscarded()
        setDiscarded(discardedFromServer.discarded);
        setDiscardedCount(discardedFromServer.discardedCount);
      }
      getDiscarded();
      setLastPageDiscarded(false)
      if (discardedPage === 1) {
        setFirstPageDiscarded(true)
      } else {
        setFirstPageDiscarded(false)
      }
    }
  }

  const [toggleState, setToggleState] = useState()

  useEffect(() => {
    if (tab > 0) {
      setToggleState(parseInt(tab))
    } else {
      setToggleState(1)
    }
  }, [])

  const setTab = (index) => {
    setToggleState(index)
  }

  return (
    <div className={`transactionsContainer ${sideMenuBlur ? 'blur' : ''}`}>
      <FixedActions toggleUnavailable={toggleUnavailable} />
      <div className='pageTitleContainer'>
        <div className="pageTitleSearch">
          <div className='pageTitle'>Proposals</div>
          <div className="textInputContainer">
            <i className="textInputIcon ri-search-2-line"></i>
            <input className="textInput search" type="text" name="name" placeholder='Search hash' onClick={() => toggleUnavailable()} />
          </div>
        </div>
      </div>
      <div className="filtersPaginationTransactions">
        <div className="filtersPagination">
          <div className="filter">
            <div className="filterItem" onClick={() => toggleUnavailable()}>
              <div className="filterItemLabel">All filters</div>
            </div>
          </div>
          <div className="pagination">
            {toggleState === 1 ?
              <>
                {needsApprovalCount > needsApprovalPerPage ? `${firstIndexNeedsApproval} - ${lastPageNeedsApproval ? needsApprovalCount : lastIndexNeedsApproval} of ` : ``}{needsApprovalCount} needs approval
                {needsApprovalCount > needsApprovalPerPage ?
                  <div className="actions">
                    <button onClick={() => { onNeedsApprovalPageDecrease() }} className={`iconbtn ${firstPageNeedsApproval ? 'disabled' : ''}`}>
                      <div className="container">
                        <i className="ri-arrow-left-s-line sml"></i>
                      </div>
                    </button>
                    <button onClick={() => { onNeedsApprovalPageIncrease() }} className={`iconbtn ${lastPageNeedsApproval ? 'disabled' : ''}`}>
                      <div className="container">
                        <i className="ri-arrow-right-s-line sml"></i>
                      </div>
                    </button>
                  </div>
                  : ``}

              </>
              : ''}
            {toggleState === 2 ?
              <>
                {readyToExecuteCount > readyToExecutePerPage ? `${firstIndexReadyToExecute} - ${lastPageReadyToExecute ? readyToExecuteCount : lastIndexReadyToExecute} of ` : ``}{readyToExecuteCount} ready to execute
                {readyToExecuteCount > readyToExecutePerPage ?
                  <div className="actions">
                    <button onClick={() => { onReadyToExecutePageDecrease() }} className={`iconbtn ${firstPageReadyToExecute ? 'disabled' : ''}`}>
                      <div className="container">
                        <i className="ri-arrow-left-s-line sml"></i>
                      </div>
                    </button>
                    <button onClick={() => { onReadyToExecutePageIncrease() }} className={`iconbtn ${lastPageReadyToExecute ? 'disabled' : ''}`}>
                      <div className="container">
                        <i className="ri-arrow-right-s-line sml"></i>
                      </div>
                    </button>
                  </div>
                  : ``}
              </>
              : ''}
            {toggleState === 3 ?
              <>
                {discardedCount > discardedPerPage ? `${firstIndexDiscarded} - ${lastPageDiscarded ? discardedCount : lastIndexDiscarded} of ` : ``}{discardedCount} discarded
                {discardedCount > discardedPerPage ?
                  <div className="actions">
                    <button onClick={() => { onDiscardedPageDecrease() }} className={`iconbtn ${firstPageDiscarded ? 'disabled' : ''}`}>
                      <div className="container">
                        <i className="ri-arrow-left-s-line sml"></i>
                      </div>
                    </button>
                    <button onClick={() => { onDiscardedPageIncrease() }} className={`iconbtn ${lastPageDiscarded ? 'disabled' : ''}`}>
                      <div className="container">
                        <i className="ri-arrow-right-s-line sml"></i>
                      </div>
                    </button>
                  </div>
                  : ``}
              </>
              : ''}


          </div>
        </div>


        <div className='viewContainer'>
          <div className='viewController'>
            <div className={`tab ${toggleState === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>
              <div className='tabTitle'>Needs approval</div>
              {needsApprovalCount > 0 ? <div className='tabCount'>{needsApprovalCount}</div> : ''}

            </div>
            <div className={`tab ${toggleState === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>
              <div className='tabTitle'>Ready to execute</div>
              {readyToExecuteCount > 0 ? <div className='tabCount'>{readyToExecuteCount}</div> : ''}
            </div>
            <div className={`tab ${toggleState === 3 ? 'active' : ''}`} onClick={() => setTab(3)}>
              <div className='tabTitle'>Discarded</div>
              {discardedCount > 0 ? <div className='tabCount'>{discardedCount}</div> : ''}
            </div>
          </div>

          <div className='viewContent'>


            <div className={`${toggleState === 1 ? 'activeTab' : ''}`}>
              <div className="transactionsList">
                <div className="transactionsHeader">
                  <div className="typeTime">Initiated</div>
                  <div className="walletNetwork">Wallet & Network</div>
                  <div className="toFrom">To</div>
                  <div className="in">Tokens</div>
                  <div className="out">Classification</div>
                  <div className="classification">Status</div>
                </div>

                <Proposal proposals={needsApproval} toggleUnavailable={toggleUnavailable} />
              </div>
              <div className="footerPagination" style={{ paddingTop: "52px" }}>
                <div className="pagination">
                  {needsApprovalCount > needsApprovalPerPage ? `${firstIndexNeedsApproval} - ${lastPageNeedsApproval ? needsApprovalCount : lastIndexNeedsApproval} of ` : ``}{needsApprovalCount} needs approval
                  {needsApprovalCount > needsApprovalPerPage ?
                    <div className="actions">
                      <button onClick={() => { onNeedsApprovalPageDecrease() }} className={`iconbtn ${firstPageNeedsApproval ? 'disabled' : ''}`}>
                        <div className="container">
                          <i className="ri-arrow-left-s-line sml"></i>
                        </div>
                      </button>
                      <button onClick={() => { onNeedsApprovalPageIncrease() }} className={`iconbtn ${lastPageNeedsApproval ? 'disabled' : ''}`}>
                        <div className="container">
                          <i className="ri-arrow-right-s-line sml"></i>
                        </div>
                      </button>
                    </div>
                    : ``}
                </div>
              </div>
            </div>



            <div className={`${toggleState === 2 ? 'activeTab' : ''}`}>
              <div className="transactionsList">
                <div className="transactionsHeader">
                  <div className="typeTime">Initiated</div>
                  <div className="walletNetwork">Wallet & Network</div>
                  <div className="toFrom">To</div>
                  <div className="in">Tokens</div>
                  <div className="out">Classification</div>
                  <div className="classification">Status</div>
                </div>

                <Proposal proposals={readyToExecute} toggleUnavailable={toggleUnavailable} />

                <div className="footerPagination" style={{ paddingTop: "52px" }}>
                  <div className="pagination">
                    {readyToExecuteCount > readyToExecutePerPage ? `${firstIndexReadyToExecute} - ${lastPageReadyToExecute ? readyToExecuteCount : lastIndexReadyToExecute} of ` : ``}{readyToExecuteCount} ready to execute
                    {readyToExecuteCount > readyToExecutePerPage ?
                      <div className="actions">
                        <button onClick={() => { onReadyToExecutePageDecrease() }} className={`iconbtn ${firstPageReadyToExecute ? 'disabled' : ''}`}>
                          <div className="container">
                            <i className="ri-arrow-left-s-line sml"></i>
                          </div>
                        </button>
                        <button onClick={() => { onReadyToExecutePageIncrease() }} className={`iconbtn ${lastPageReadyToExecute ? 'disabled' : ''}`}>
                          <div className="container">
                            <i className="ri-arrow-right-s-line sml"></i>
                          </div>
                        </button>
                      </div>
                      : ``}
                  </div>
                </div>
              </div>
            </div>



            <div className={`${toggleState === 3 ? 'activeTab' : ''}`}>
              <div className="transactionsList">
                <div className="transactionsHeader">
                  <div className="typeTime">Initiated</div>
                  <div className="walletNetwork">Wallet & Network</div>
                  <div className="toFrom">To</div>
                  <div className="in">Tokens</div>
                  <div className="out">Classification</div>
                  <div className="classification">Status</div>
                </div>

                <Proposal proposals={discarded} toggleUnavailable={toggleUnavailable} />

                <div className="footerPagination" style={{ paddingTop: "52px" }}>
                  <div className="pagination">


                    {discardedCount > discardedPerPage ? `${firstIndexDiscarded} - ${lastPageDiscarded ? discardedCount : lastIndexDiscarded} of ` : ``}{discardedCount} discarded
                    {discardedCount > discardedPerPage ?
                      <div className="actions">
                        <button onClick={() => { onDiscardedPageDecrease() }} className={`iconbtn ${firstPageDiscarded ? 'disabled' : ''}`}>
                          <div className="container">
                            <i className="ri-arrow-left-s-line sml"></i>
                          </div>
                        </button>
                        <button onClick={() => { onDiscardedPageIncrease() }} className={`iconbtn ${lastPageDiscarded ? 'disabled' : ''}`}>
                          <div className="container">
                            <i className="ri-arrow-right-s-line sml"></i>
                          </div>
                        </button>
                      </div>
                      : ``}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Proposals
