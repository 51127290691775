import Member from './Member'

const Members = ({ workspaces, members, wallets }) => {
    let tempMembers = []

    // Create a map to store member role based on their role in wallets and workspaces
    const memberRoleMap = {};

    // Create a map to store the count of each wallet type for each member
    const memberWalletTypeCountMap = {};

    // Iterate over wallets and update member role based on their role
    // Only include unique wallets in the iteration
    const uniqueWallets = [...new Set(wallets)];
    uniqueWallets.forEach(wallet => {
        if (wallet.type === 1 || wallet.type === 2) {
            wallet.members.forEach(member => {
                if (Array.isArray(member.role)) {
                    member.role.forEach(role => {
                        if (role === "admin") {
                            return memberRoleMap[member.memberId] = "admin";
                        }
                    })
                } else {
                    if (!memberRoleMap[member.memberId]) {
                        memberRoleMap[member.memberId] = member.role;
                    }
                }
                // Increment the count of the wallet type for the member
                memberWalletTypeCountMap[member.memberId] = memberWalletTypeCountMap[member.memberId] || {}
                memberWalletTypeCountMap[member.memberId][wallet.type] = (memberWalletTypeCountMap[member.memberId][wallet.type] || 0) + 1;
            });
        }
    });

    // Update member role in workspaces with the mapped role
    workspaces.forEach(workspace => {
        workspace.members.forEach(member => {
            member.role = memberRoleMap[member.memberId];
        });
    });

    workspaces.forEach(workspace => {
        if (workspace.active) {
            workspace.members.forEach(m => {
                members.forEach(member => {
                    if (m.memberId === member.id) {
                        let tempMember = member
                        tempMember.role = m.role
                        tempMember.status = m.status
                        tempMember.walletTypeCount = memberWalletTypeCountMap[member.id]
                        tempMembers.push(tempMember)
                    }
                })
            })
        }
    })

    return (
        <div className="membersContainer">
            <div className="titleAction">
                <div className='pageTitle'>Members</div>
                <button className='filledbtn largeFilledBtn neutralFilledBtn'><div className='label'>Invite member</div></button>
            </div>
            <div className="memberList">
                {tempMembers.map(member => {
                    return <Member member={member} key={member.id} />
                })}
            </div>
        </div>
    )
}

export default Members