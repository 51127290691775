import { useState, useEffect } from 'react';

import optimismIcon from "./../icons/blockchain/OPTIMISM.svg";
import BSC from "./../icons/blockchain/BSC.svg";
import Ethereum from "./../icons/blockchain/Ethereum.svg";
import gnosisChainIcon from "./../icons/blockchain/Gnosis.svg";
import polygonIcon from "./../icons/blockchain/Polygon.svg";
import solanaIcon from "./../icons/blockchain/SOL.svg";

const PolicyDetails = ({ walletDetails, members }) => {

    const [cryptos, setCryptos] = useState([])

    useEffect(() => {
        const getCryptos = async () => {
            const cryptosFromServer = await fetchCryptos()
            setCryptos(cryptosFromServer);
        }
        getCryptos();
    }, [])

    const fetchCryptos = async () => {
        const res = await fetch('https://cmcserver.onrender.com/cryptocurrencies')
        const data = await res.json()
        return data
    }

    const blockchainIcon = (chainName) => {
        if (Array.isArray(chainName)) {
            return [Ethereum, polygonIcon, BSC];
        }
        if (chainName.match("Optimism")) {
            return optimismIcon;
        }
        else if (chainName.match("BSC")) {
            return BSC;
        }
        else if (chainName.match("Ethereum")) {
            return Ethereum;
        }
        else if (chainName.match("Gnosis Chain")) {
            return gnosisChainIcon;
        }
        else if (chainName.match("Polygon")) {
            return polygonIcon;
        }
        else if (chainName.match("Solana")) {
            return solanaIcon;
        }
    }

    let approverCount = 0;
    let approverList = [];
    members.forEach(member => {
        if (Array.isArray(member.role)) {
            member.role.forEach(role => {
                if (role === 'approver') {
                    approverCount++
                    approverList.push(member)
                }
            })
        } else if (member.role === 'approver') {
            approverCount++
            approverList.push(member)
        }
    });

    let noAssetPolicy = !walletDetails.hasOwnProperty("blockedAssets") && !walletDetails.hasOwnProperty("allowedAssets")

    let assetsTemp = []
    let assetTemp = []
    if (walletDetails.hasOwnProperty("blockedAssets")) {
        if (Array.isArray(walletDetails.blockedAssets)) {
            walletDetails.blockedAssets.forEach(asset => {
                cryptos.forEach(crypto => {
                    if (crypto.id === asset.assetId) {
                        assetTemp = {
                            assetId: asset.assetId,
                            assetName: crypto.symbol
                        }
                        assetsTemp.push(assetTemp)
                    }
                })
            })
        } else {
            cryptos.forEach(crypto => {
                if (crypto.id === walletDetails.blockedAssets) {
                    assetTemp = {
                        assetId: walletDetails.blockedAssets,
                        assetName: crypto.symbol
                    }
                    assetsTemp.push(assetTemp)
                }
            })

        }
    }

    if (walletDetails.hasOwnProperty("allowedAssets")) {
        if (Array.isArray(walletDetails.allowedAssets)) {
            walletDetails.allowedAssets.forEach(asset => {
                cryptos.forEach(crypto => {
                    if (crypto.id === asset.assetId) {
                        assetTemp = {
                            assetId: asset.assetId,
                            assetName: crypto.symbol
                        }
                        assetsTemp.push(assetTemp)
                    }
                })
            })
        } else {
            cryptos.forEach(crypto => {
                if (crypto.id === walletDetails.allowedAssets) {
                    assetTemp = {
                        assetId: walletDetails.allowedAssets.assetId,
                        assetName: crypto.symbol
                    }
                    assetsTemp.push(assetTemp)
                }
            })

        }
    }

    return (
        <>
            <div className='policy'>
                <div className='policyTitleContainer'>
                    <div className='policyTitle'>
                        Approval policy
                    </div>
                    <div className='policyMetaTitle'>
                        {/* {approverCount} out of {members.length} approvals */}
                        {walletDetails.threshold} out of {approverCount} approvals
                    </div>
                </div>
                <div className='approversList'>
                    {approverList.map(member => {
                        return <div className='approverName' key={member.id}>{member.name}</div>
                    })}
                </div>
            </div>
            <div className='policy'>
                {walletDetails.hasOwnProperty("transactionLimit") ?
                    <>
                        <div className='policyTitleContainer'>
                            <div className='policyTitle'>
                                Limit per transaction
                            </div>
                        </div>
                        <div className='transactionLimit'>
                            ${walletDetails.transactionLimit}
                        </div>
                    </>
                    :
                    <>
                        <div className='policyTitleContainer'>
                            <div className='policyTitle'>
                                Set transaction limit
                            </div>
                        </div>
                        <div className='approversList'>
                            <div className='approverName'>
                                Set limit on each transaction made using this wallet. Contact Admin.
                            </div>
                        </div>
                    </>
                }

            </div>
            <div className='policy'>

                {walletDetails.hasOwnProperty("blockedNetworks") ?
                    <>
                        <div className='policyTitleContainer'>
                            <div className='policyTitle'>
                                Blocked networks
                            </div>
                        </div>
                        <div className='blockedNetworksList'>
                            {Array.isArray(walletDetails.blockedNetworks) ?
                                walletDetails.blockedNetworks.map(network => {
                                    return <div key={network.blockchain} className='blockedNetwork'>
                                        <img src={blockchainIcon(network.blockchain)} className="blockedNetworkIcon" />
                                        <div className='blockedNetworkLabel'> {network.blockchain} </div>
                                    </div>
                                })
                                :
                                <div key={walletDetails.blockedNetworks} className='blockedNetwork'>
                                    <img src={blockchainIcon(walletDetails.blockedNetworks)} className="blockedNetworkIcon" />
                                    <div className='blockedNetworkLabel'> {walletDetails.blockedNetworks} </div>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='policyTitleContainer'>
                            <div className='policyTitle'>
                                Set blockchain limit
                            </div>
                        </div>
                        <div className='approversList'>
                            <div className='approverName' >
                                You can block network(s) on this wallet. Contact Admin.
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className='policy'>

                {noAssetPolicy ?
                    <>
                        <div className='policyTitleContainer'>
                            <div className='policyTitle'>
                                Set assets limit
                            </div>
                        </div>
                        <div className='approversList'>
                            <div className='approverName' >
                                You can block or allow assets on this wallet. Contact Admin.
                            </div>
                        </div>
                    </>
                    : ''}

                {walletDetails.hasOwnProperty("blockedAssets") ?
                    <>
                        <div className='policyTitleContainer'>
                            <div className='policyTitle'>
                                Assets limit
                            </div>
                            <div className='policyMetaTitle'>
                                {Array.isArray(walletDetails.blockedAssets) ?
                                    <>{walletDetails.blockedAssets.length} blocked</> :
                                    <>1 blocked</>
                                }

                            </div>
                        </div>
                        <div className='assetsLimitList'>
                            {Array.isArray(walletDetails.blockedAssets) ?
                                assetsTemp.map(blockedAsset => {
                                    return <div key={blockedAsset.assetId} className='assetsLimit'>
                                        <i className={`cf cf-${(blockedAsset.assetName).toLowerCase()} assetsLimitIcon`}></i>
                                        <div className='assetsLimitLabel' key={blockedAsset.assetId}> {blockedAsset.assetName} </div>
                                    </div>
                                })
                                : 
                                <div key={assetsTemp[0].assetId} className='assetsLimit'>
                                    <i className={`cf cf-${(assetsTemp[0].assetName).toLowerCase()} assetsLimitIcon`}></i>
                                    <div className='assetsLimitLabel' key={assetsTemp[0].assetId}> {assetsTemp[0].assetName} </div>
                                </div>
                            }
                        </div>
                    </>
                    : ''
                }
                {walletDetails.hasOwnProperty("allowedAssets") ?
                    <>
                        <div className='policyTitleContainer'>
                            <div className='policyTitle'>
                                Assets limit
                            </div>
                            <div className='policyMetaTitle'>
                                {Array.isArray(walletDetails.allowedAssets) ?
                                    <>{walletDetails.allowedAssets.length} allowed</> :
                                    <>1 allowrd</>
                                }
                            </div>
                        </div>
                        <div className='assetsLimitList'>
                            {Array.isArray(walletDetails.allowedAssets) ?
                                assetsTemp.map(allowedAsset => {
                                    return <div key={allowedAsset.assetId} className='assetsLimit'>
                                        <i className={`cf cf-${(allowedAsset.assetName).toLowerCase()} assetsLimitIcon`}></i>
                                        <div className='assetsLimitLabel' key={allowedAsset.assetId}> {allowedAsset.assetName} </div>
                                    </div>
                                })
                                :
                                <div className='assetsLimitLabel' key={assetTemp.assetId}> {assetTemp.assetName} </div>
                            }
                        </div>
                    </>
                    : ''
                }

            </div>
        </>
    )
}

export default PolicyDetails
