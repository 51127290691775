import TrackedWallet from './TrackedWallet'
import GnosisSafe from './GnosisSafe'
import MPCWallet from './MPCWallet'
import FixedActions from './FixedActions';

import { useNavigate } from 'react-router-dom';

import BSC from "./../icons/blockchain/BSC.svg";
import ethereumIcon from "./../icons/blockchain/Ethereum.svg";
import polygonIcon from "./../icons/blockchain/Polygon.svg";

const Wallets = ({ wallets, cryptos, sideMenuBlur, toggleUnavailable }) => {
  const navigate = useNavigate()

  const handleWalletClick = walletId => {
    navigate(`/wallet/${walletId}`);
  };


  const mpcWallet = [], gnosisSafe = [], trackedWallet = [];
  wallets.forEach(wallet => {
    if (wallet.type === 1) {
      mpcWallet.push(wallet);
    }
    else if (wallet.type === 2) {
      gnosisSafe.push(wallet);
    }
    else {
      trackedWallet.push(wallet);
    }
  })

  return (
    <div className={`walletsContainer ${sideMenuBlur ? 'blur' : ''}`}>
      <FixedActions toggleUnavailable={toggleUnavailable} />
      <div className='pageTitleContainer pageTitle'>Wallets</div>
      {mpcWallet.length === 0 && gnosisSafe.length === 0 && trackedWallet.length === 0 ?
        <div className='emptyWallets'>
          <div className='titleContainer'>
            <div className='title'>
              Create an MPC wallet or connect with an existing Gnosis Safe.
            </div>
            <div className='subtitle'>
              Secure your assets in MPC wallets, use your existing wallet or track any EVM wallet.
            </div>
          </div>
          <div className='actions'>
            <button className='filledbtn largeFilledBtn brandFilledBtn' onClick={() => toggleUnavailable()} > <div className='label'>Create MPC Wallet</div></button>
            <button className='filledbtn largeFilledBtn neutralFilledBtn' onClick={() => toggleUnavailable()} > <div className='label'>Connect Safe</div></button>
            <button className='textbtn large neutral' onClick={() => toggleUnavailable()} ><div className='label'>Track a wallet</div></button>
          </div>
        </div>

        : <>
          <div className='walltsMainContainer'>
            <div className='walletTitle'>
              <div className='walletTitleLabelContainer'>
                <div className='walletTitleLabel'>MPC Wallets</div>
                <div className='walletTitleIcons'>
                  <img src={ethereumIcon} className="titleIcon" />
                  <img src={polygonIcon} className="titleIcon" />
                  <img src={BSC} className="titleIcon" />
                </div>
              </div>

              <button onClick={() => toggleUnavailable()} className="textButton">
                <div className='textButtonLabel'>
                  Create MPC Wallet
                </div>
              </button>
            </div>

            <div className='mpcWalletContainer'>

              {mpcWallet.length > 0 ? <MPCWallet mpcWallet={mpcWallet} cryptos={cryptos} handleClick={handleWalletClick} toggleUnavailable={toggleUnavailable} /> :
                ""
              }
            </div>
          </div>
          <div className='walltsMainContainer'>
            <div className='walletTitle'>
              <div className='walletTitleLabel'>Gnosis Safe</div>
              <button onClick={() => toggleUnavailable()} className="textButton">
                <div className='textButtonLabel'>
                  Connect Safe
                </div>
              </button>
            </div>
            <div className='gnosisSafeContainer'>
              {gnosisSafe.length > 0 ? <GnosisSafe gnosisSafe={gnosisSafe} cryptos={cryptos} handleClick={handleWalletClick} toggleUnavailable={toggleUnavailable} /> :
                ""}

            </div>
          </div>

          <div className='walltsMainContainer'>
            <div className='walletTitle'>
              <div className='walletTitleLabel'>Tracked Wallets</div>
              <button onClick={() => toggleUnavailable()} className="textButton">
                <div className='textButtonLabel'>
                  Track wallet
                </div>
              </button>
            </div>
            <div className='gnosisSafeContainer'>
              {trackedWallet.length > 0 ? <TrackedWallet trackedWallet={trackedWallet} cryptos={cryptos} toggleUnavailable={toggleUnavailable} /> :
                ""}

            </div>
          </div>
        </>}
    </div>
  )
}

export default Wallets
