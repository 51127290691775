import { useState } from "react"


const EmailNotifications = () => {
    const [toggleMain, setToggleMain] = useState(true)
    const [allNotification, setAllNotification] = useState(true)
    const [transactionsNotification, setTtransactionsNotification] = useState(true)
    const [mpcWalletNotification, setMpcWalletNotification] = useState(true)
    const [gnosisSafeNotification, setGnosisSafeNotification] = useState(true)
    const [policyNotification, setPolicyNotification] = useState(true)
    const [memberNotification, setMemberNotification] = useState(true)
    const [walletNotification, setWalletNotification] = useState(true)

    const toggleAll = () => {
        setAllNotification(!allNotification)
        if (allNotification === true) {
            setTtransactionsNotification(false)
            setMpcWalletNotification(false)
            setGnosisSafeNotification(false)
            setPolicyNotification(false)
            setMemberNotification(false)
            setWalletNotification(false)
        }
        if (allNotification === false) {
            setTtransactionsNotification(true)
            setMpcWalletNotification(true)
            setGnosisSafeNotification(true)
            setPolicyNotification(true)
            setMemberNotification(true)
            setWalletNotification(true)
        }
    }

    const toggleTransactions = () => {
        setTtransactionsNotification(!transactionsNotification)
    }

    const toggleMPC = () => {
        setMpcWalletNotification(!mpcWalletNotification)
    }

    const toggleGnosisSafe = () => {
        setGnosisSafeNotification(!gnosisSafeNotification)
    }

    const togglePolicy = () => {
        setPolicyNotification(!policyNotification)
    }

    const toggleMember = () => {
        setMemberNotification(!memberNotification)
    }

    const toggleWallet = () => {
        setWalletNotification(!walletNotification)
    }

    const toggleMainSwtich = () => {
        setToggleMain(!toggleMain)
        toggleAll()
    }
    return (
        <div className="emailNotificationsContainer">
            <div className="emailNotificationsPageTitle">
            <div className="pageTitle">
                    Email
                </div>
            </div>
            <div className="controls">
                <div className="allEmails" onClick={() => { toggleMainSwtich() }}>
                    <div className="mainContainer">
                        <div className={`toggleSwitch ${toggleMain ? 'on' : ''}`}>
                            <div className="swtichDot"></div>
                        </div>
                        <div className="label">Send email notifications</div>
                    </div>
                    <div className="metadata">
                        Inform workspace members about activities over email.
                    </div>
                </div>
                <div className="metaControls">
                    <div className="leadControl">
                        <div className={`checkboxContainer ${toggleMain ? '' : 'disable'}`} onClick={() => { toggleAll() }}>
                            <div className="checkboxLabel">
                                {
                                    transactionsNotification &&
                                    mpcWalletNotification &&
                                    gnosisSafeNotification &&
                                    policyNotification &&
                                    memberNotification &&
                                    walletNotification  ?
                                    <i className="ri-checkbox-fill checkbox"></i> :

                                    transactionsNotification ||
                                        mpcWalletNotification ||
                                        gnosisSafeNotification ||
                                        policyNotification ||
                                        memberNotification ||
                                        walletNotification  ?
                                        <i className="ri-checkbox-indeterminate-fill checkbox"></i> : allNotification && toggleMain ? <i className="ri-checkbox-fill checkbox"></i> : <i className="ri-checkbox-blank-line checkbox"></i>
                                }
                                {}
                                <div className="label">
                                    All notifications
                                </div>
                            </div>
                            <div className="metadata">All activity will be notified to all members.</div>
                        </div>
                    </div>

                    <div className="trailControls">
                        <div className={`checkboxContainer ${toggleMain ? '' : 'disable'}`} onClick={() => { toggleTransactions() }}>
                            <div className="checkboxLabel">
                                {transactionsNotification && toggleMain ? <i className="ri-checkbox-fill checkbox"></i> : <i className="ri-checkbox-blank-line checkbox"></i>}
                                <div className="label">
                                    Transactions
                                </div>
                            </div>
                            <div className="metadata">All transactions will be notified to their approvers.</div>
                        </div>
                        <div className={`checkboxContainer ${toggleMain ? '' : 'disable'}`} onClick={() => { toggleMPC() }}>
                            <div className="checkboxLabel">
                                {mpcWalletNotification && toggleMain ? <i className="ri-checkbox-fill checkbox"></i> : <i className="ri-checkbox-blank-line checkbox"></i>}
                                <div className="label">
                                    MPC Wallets
                                </div>
                            </div>
                            <div className="metadata">Activities in an MPC wallet will be notified to their approvers & admin</div>
                        </div>
                        <div className={`checkboxContainer ${toggleMain ? '' : 'disable'}`} onClick={() => { toggleGnosisSafe() }}>
                            <div className="checkboxLabel">
                                {gnosisSafeNotification && toggleMain ? <i className="ri-checkbox-fill checkbox"></i> : <i className="ri-checkbox-blank-line checkbox"></i>}
                                <div className="label">
                                    Gnosis Safe
                                </div>
                            </div>
                            <div className="metadata">Safe activity will be notified to their signers & admin.</div>
                        </div>
                        <div className={`checkboxContainer ${toggleMain ? '' : 'disable'}`} onClick={() => { togglePolicy() }}>
                            <div className="checkboxLabel">
                                {policyNotification && toggleMain ? <i className="ri-checkbox-fill checkbox"></i> : <i className="ri-checkbox-blank-line checkbox"></i>}
                                <div className="label">
                                    Policy
                                </div>
                            </div>
                            <div className="metadata">Any change in MPC wallet policy will be notified to approvers.</div>
                        </div>
                        <div className={`checkboxContainer ${toggleMain ? '' : 'disable'}`} onClick={() => { toggleMember() }}>
                            <div className="checkboxLabel">
                                {memberNotification && toggleMain ? <i className="ri-checkbox-fill checkbox"></i> : <i className="ri-checkbox-blank-line checkbox"></i>}
                                <div className="label">
                                    Members
                                </div>
                            </div>
                            <div className="metadata">New invitation, suspension will be notified to existing members.</div>
                        </div>
                        <div className={`checkboxContainer ${toggleMain ? '' : 'disable'}`} onClick={() => { toggleWallet() }}>
                            <div className="checkboxLabel">
                                {walletNotification && toggleMain ? <i className="ri-checkbox-fill checkbox"></i> : <i className="ri-checkbox-blank-line checkbox"></i>}
                                <div className="label">
                                    Wallets
                                </div>
                            </div>
                            <div className="metadata">Tracked wallet transactions will be notified to all members.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailNotifications
