import Moment from 'react-moment';

const Address = ({ addresses, onAddressCheckbox, toggleUnavailable }) => {


    return (
        <>
            {addresses.map((address) => (
                <div key={address.id} className={`address ${address.selected ? 'addressSelected' : ''}`} >
                    <div className="nameContainer">
                        <button onClick={() => onAddressCheckbox(address.id)} className="iconbtn brand">
                            <div className="container">
                                {address.selected ? <i className="ri-checkbox-line neutral"></i> : <i className="ri-checkbox-blank-line neutral"></i>}
                            </div>
                        </button>
                        <div className="nameLabel">{address.name}</div>
                    </div>
                    <div className="addressHash">
                        <i className={`ri-verified-badge-line ${address.verified ? 'verified' : 'unverified'}`}></i>
                        <div className="hash">{address.walletHash}</div>
                    </div>
                    {address.hasOwnProperty('lastInteracted') ?
                        <div className="lastInteracted">
                            <i className="ri-history-line interactedIcon"></i>
                            <Moment className="interactedDate" date={address.lastInteracted} format='MMM Do YYYY' />
                        </div>
                        :
                        <div className="lastInteracted" style={{ opacity: "50%" }}>
                            <i className="ri-history-line interactedIcon"></i>
                            <div className="interactedDate">Never interacted</div>
                        </div>
                    }
                    <div className="groups">
                        {address.groups.length > 1 ?
                            address.groups.slice(0, 1).map(group => {
                                return <div className="groupItem" key={group.groupId}>{group.groupName}</div>
                            })
                            :
                            address.groups.map(group => {
                                return <div className="groupItem" key={group.groupId}>{group.groupName}</div>
                            })
                        }
                        {address.groups.length > 1 ?
                            <>
                                <div className="groupItem">+{address.groups.length - 1} groups</div>
                                <div className='groupHover'>
                                {address.groups.slice(1).map(group => {
                                    return <div className="groupInHover" key={group.groupId}>{group.groupName}</div>
                                })}
                                </div>
                            </>
                            : ''
                        }
                        {address.groups.length === 0 ?
                            <div className="groupItem" style={{ opacity: "50%" }}>No groups</div>
                            : ''
                        }
                    </div>
                    <div className="actions">
                        <button  onClick={() => toggleUnavailable()} className="iconbtn brand">
                            <div className="container">
                                <i className="ri-edit-line neutral" style={{ fontSize: "24px" }}></i>
                            </div>
                        </button>
                        <button  onClick={() => toggleUnavailable()} className="iconbtn brand">
                            <div className="container">
                                <i className="ri-send-plane-line brand" style={{ fontSize: "24px" }}></i>
                            </div>
                        </button>
                    </div>
                </div>
            ))}

        </>

    )
}

export default Address
