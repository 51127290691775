

const FixedActions = ({toggleUnavailable}) => {
  return (
    <div className="actionContainers">
        <button className={`strokeBtn largeStrokeBtn neutralStrokeBtn noIcon`} onClick={() => toggleUnavailable()}><div className='label'>Move tokens</div></button>
        <button className='strokeBtn largeStrokeBtn neutralStrokeBtn onlyIcon' onClick={() => toggleUnavailable()}><i className="ri-flashlight-line"></i></button>
    </div>
  )
}

export default FixedActions