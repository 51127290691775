import AssetExpanded from "./AssetExpanded";

import optimismIcon from "./../icons/blockchain/OPTIMISM.svg";
import BSC from "./../icons/blockchain/BSC.svg";
import Ethereum from "./../icons/blockchain/Ethereum.svg";
import gnosisChainIcon from "./../icons/blockchain/Gnosis.svg";
import polygonIcon from "./../icons/blockchain/Polygon.svg";
import solanaIcon from "./../icons/blockchain/SOL.svg";



const negPos = (number) => {
    if (Math.sign(number) > 0) {
        return <i className="ri-arrow-up-line success"></i>;
    } else if (Math.sign(number) < 0) {
        return <i className="ri-arrow-down-line negative"></i>;
    }
    return <i className="ri-number-0"></i>;
}

const negPosStyle = (number) => {
    if (Math.sign(number) > 0) {
        return "#069668";

    } else if (Math.sign(number) < 0) {
        return "#963606";
    }
    return "#71717A";
}

const blockchainIcon = (chainName) => {
    if (Array.isArray(chainName)) {
        return [Ethereum, polygonIcon, BSC];
    }
    if (chainName.match("Optimism")) {
        return optimismIcon;
    }
    else if (chainName.match("BSC")) {
        return BSC;
    }
    else if (chainName.match("Ethereum")) {
        return Ethereum;
    }
    else if (chainName.match("Gnosis Chain")) {
        return gnosisChainIcon;
    }
    else if (chainName.match("Polygon")) {
        return polygonIcon;
    }
    else if (chainName.match("Solana")) {
        return solanaIcon;
    }
}




const Asset = ({ assets, cryptos, handleAssetClick }) => {

    return (

        <>
            {
                assets.map((asset) => (

                    <div className={`assetContainer ${asset.expanded ? 'expanded' : ''}`} key={asset.uniqueId} id={asset.assetId} onClick={() => { handleAssetClick(asset.uniqueId) }}>
                        <div className="asset">
                            <div className="tokenNetwork">
                                <div className="tokenNetworkIcons">
                                    <i className={`cf cf-${asset.symbol} tokenIcon`}></i>
                                    {Array.isArray(asset.blockchain) ?
                                        <div className="networkIconsContainer">
                                            {(asset.blockchain).map(blockchain => {
                                                return <img src={blockchainIcon(blockchain.blockchain)} className="networkIcon" key={blockchain.blockchain} />
                                            })}
                                        </div>
                                        : <img src={blockchainIcon(asset.blockchain)} className="networkIcon" />}
                                    {asset.blockchain === "Gnosis chain" ?
                                        <i className="cf cf-gno" style={{
                                            fontSize: "16px", position: "absolute",
                                            left: "24px",
                                            bottom: "24px",
                                            color: "#34785E",
                                            borderRadius: "100%",
                                            backgroundColor: "white"
                                        }}></i>
                                        : ''}
                                </div>
                                <div className="tokennameNetwork">
                                    <div className="tokenName">{asset.name}</div>
                                    {/* <div className="tokenPercentage">{asset.percentageShareInUSD}%</div> */}
                                </div>

                            </div>
                            <div className="price">
                                <div className="usd">${(Math.round((asset.price) * 100) / 100).toFixed(2)}</div>
                                <div className="trend">
                                    {negPos(asset.percent_change_24h)}
                                    <div className="trendLabel" style={{ color: negPosStyle(asset.percent_change_24h) }}>{asset.percent_change_24h < 0 ? (Math.round((asset.percent_change_24h * -1) * 100) / 100).toFixed(2) : (Math.round((asset.percent_change_24h) * 100) / 100).toFixed(2)}%</div>
                                </div>

                            </div>
                            <div className="tokenValue">
                                <div className="tokens">{asset.totalTokens} {asset.symbol}</div>
                                <div className="usdContainer">
                                    <div className="usd">${Math.trunc(asset.totalTokens * asset.price)}</div>
                                    <div className="cents">{((asset.totalTokens * asset.price).toFixed(2)).toString().substring(((asset.totalTokens * asset.price).toFixed(2)).toString().indexOf("."))}</div>
                                </div>
                            </div>
                            <div className="wallets">
                                <div className="count">{asset.countOfWallets}</div>

                                {asset.expanded ? <i className="ri-contract-up-down-line expandIcon"></i> : <i className="ri-expand-up-down-line expandIcon"></i>}
                            </div>
                        </div>


                        {asset.expanded ? <AssetExpanded wallets={asset.wallets} cryptos={cryptos} /> : ''}









                    </div>
                ))
            }



        </>
    )
}

export default Asset
