import emptySlack from '../icons/illustration/emptySlack.svg'

const SlackNotifications = () => {
    return (
        <div className="emailNotificationsContainer">
            <div className="emailNotificationsPageTitle">
                <div className="pageTitle">
                    Slack
                </div>
                <div className="pageSubTitle">
                    Stream notifications to Slack channel to keep your team upto date.
                </div>
            </div>
            <div className="emptyState">
                <div className="text">
                    <div className="title">
                        Now stream notifications to users or channels.
                    </div>
                    <div className="subTitle">
                        Keep your team updated on Slack about the work they most care about.
                    </div>
                </div>
                <button className='filledbtn largeFilledBtn neutralFilledBtn iconLeft' style={{zIndex: "1"}}><i className="ri-slack-line icon"></i> <div className='label'>Connect</div></button>
                <img className="slackIllustration" src={emptySlack}/>
            </div>
        </div>
    )
}

export default SlackNotifications
