import Asset from "./Asset"
import FixedActions from './FixedActions';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';


const Assets = ({ sideMenuBlur, toggleUnavailable }) => {
  const params = useParams()
  const location = useLocation()

  const [assets, setAssets] = useState([])

  useEffect(() => {
    const getAssets = async () => {
      const assetsFromServer = await fetchAssets()
      setAssets(assetsFromServer);
    }
    getAssets();
  }, [])

  const fetchAssets = async () => {
    const queryParams = new URLSearchParams(location.search)
    const walletId = queryParams.get('walletId')
    if (walletId) {
      const res = await fetch(`https://cmcserver.onrender.com/assets/?walletId=${walletId}`)
      const data = await res.json()
      return data
    }
    const res = await fetch('https://cmcserver.onrender.com/assets/')
    const data = await res.json()
    return data
  }

  const handleAssetClick = uniqueId => {
    let newAssets = []
    newAssets = assets.map(asset =>
      asset.uniqueId === uniqueId
        ? {
          ...asset,
          expanded: !asset.expanded
        }
        : {
          ...asset,
          expanded: false
        }
    )
    setAssets(newAssets)
  };

  return (
    <div className={`assetsContainer ${sideMenuBlur ? 'blur' : ''}`}>
      <FixedActions toggleUnavailable={toggleUnavailable} />
      <div className='pageTitleContainer pageTitle'>Assets</div>
      <div className="filtersAssets">
        <div className="filters">
          <div className="filterItem" onClick={() => toggleUnavailable()}>
            <div className="filterItemLabel">Networks</div>
          </div>

          <div className="filterItem" onClick={() => toggleUnavailable()}>
            <div className="filterItemLabel">Wallets</div>
          </div>

          <div className="filterItem" onClick={() => toggleUnavailable()}>
            <div className="filterItemLabel">Tokens</div>
          </div>


        </div>
        <div className="assetsList">
          <div className="assetsHeaders">
            <div className="tokensNetwork">Tokens & Network</div>
            <div className="priceTrend">
              <div className="price">Price</div>
              <div className="trend">Last 24 hours</div>
            </div>
            <div className="tokenValueContainer">
              <div className="tokenTitle">Token Value</div>
              <div className="usdTitle">USD</div>
            </div>
            <div className="wallets">Wallets</div>
          </div>
          <Asset assets={assets} handleAssetClick={handleAssetClick} />
        </div>
      </div>
    </div>
  )
}

export default Assets
