

const TransactionExpanded = ({ transaction }) => {
    return (
        <div className="transactionDetails">
            {transaction.walletType === 1 || transaction.walletType === 2 ?
                <div className="timeline">
                    <div className="initiatedContainer">
                        <div className="statusContainer">
                            <div className="statusIcon">
                                <div className="statusDot"></div>
                            </div>
                            <div className="statusLabel">Initiated</div>
                        </div>
                        <div className="initiaterContainer">
                            <div className="initiaterName">by {transaction.initiator}</div>
                            <div className="initiatedTime">11:45</div>
                        </div>
                    </div>
                    <div className="approverContainer">


                        <div className="statusContainer">
                            <div className="statusIcon">
                                <div className="statusDot"></div>
                            </div>
                            <div className="statusLabel">Approved</div>
                        </div>
                        <div className="approverNameContainer">
                            <div className="approverName">
                            <button className='textbtn small neutralBrand'> <div className='label'>View proposal</div></button>
                            </div>
                            <div className="approvedTime">11:45</div>
                        </div>

                    </div>
                    <div className="executerContainer">


                        <div className="statusContainer">
                            <div className="statusIcon">
                                <div className="statusDot"></div>
                            </div>
                            <div className="statusLabel">Executed</div>
                        </div>
                        <div className="executerNameContainer">
                            <div className="executerName">by {transaction.executer}</div>
                            <div className="executedTime">11:45</div>
                        </div>
                    </div>
                </div>
                : ""}
            <div className="metadata">
                <div className="hash">
                    <div className="hashTitle">Hash</div>
                    <div className="hashValue">0x28443d391cf2b4ba2f5df60eaz35fbfeb9774049ffa7dc180adf959d31deafec</div>
                </div>
                <div className="gasFee">
                    <div className="gasFeeTitle">Gas Fee</div>
                    <div className="gasFeeValue">0.000012 ETH</div>
                </div>
            </div>
        </div>
    )
}

export default TransactionExpanded
