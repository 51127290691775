import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const SideNav = ({ workspaces, members, workspaceToggle, workspaceToggler, memberMenuToggle, memberMenuToggler, adminSettings, adminToggler, backToWorkspace }) => {
    const [toggleState, setToggleState] = useState(2)
    const [workspaceState, setWorkspaceState] = useState(1)

    const setTab = (index) => {
        setToggleState(index)
    }

    const setWorkspace = (index) => {
        workspaces.forEach(workspace => {
            console.log(workspace)
            if (index === workspace.id) {
                setWorkspaceState(workspace.id)
            }
        })
    }

    const viewAdmin = () => {
        adminToggler()
        setTab(6)
        navigate('/members')
    }

    const navigate = useNavigate()

    const back = () => {
        backToWorkspace()
        setTab(2)
        navigate('/wallets')
    }

    return (
        <div className="sidenavContainer">

            <div className={`sidenav ${adminSettings ? `` : 'sidenavActive'}`}>
                <div className="workspace">
                    <div className="workspaceTitle">
                        <i className="workspaceIcon ri-planet-line"></i>
                        <div className="workspaceLabel">Acme & Co.</div>
                    </div>
                    <div className="workspaceOptions">
                        {workspaceToggle ?
                            '' :
                            <i className="workspaceOptionsIcon ri-settings-line" onClick={() => viewAdmin()}></i>
                        }
                        {workspaceToggle ?
                            <i className="workspaceOptionsIcon ri-contract-up-down-line" onClick={() => workspaceToggler()}></i> :
                            <i className="workspaceOptionsIcon ri-expand-up-down-line" onClick={() => workspaceToggler()}></i>
                        }
                    </div>
                </div>
                {workspaceToggle ?
                    <>
                        <div className="noteContainer">
                            <div className='note'>
                                <div className='noteTitle'>
                                    Note!
                                </div>
                                <div className='noteDescription'>
                                    Features in this menu is unavailable for this demo.
                                </div>
                            </div>
                        </div>
                        <div className="navmenu">
                            <div className="submenu">
                                <div className="submenuTitle">Workspaces</div>
                                {workspaces.map(workspace => {
                                    return <Link to='/' className={`navItem ${workspaceState === workspace.id ? 'active' : ''}`} key={workspace.id} style={{ textDecoration: "none" }} onClick={() => { setWorkspace(workspace.id) }}>
                                        <i className="ri-planet-line navItemIcon"></i>
                                        <div className='navItemLabel'>{workspace.name}</div>
                                    </Link>
                                })}
                            </div>
                        </div>
                    </>
                    :
                    <div className="navmenu">
                        <div className="submenu">
                            <div className="submenuTitle">View and manage</div>
                            {/* <Link to='/' className={`navItem  ${toggleState === 1 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(1) }}>
                                <i className="ri-home-line navItemIcon"></i>
                                <div className='navItemLabel'>Home</div>
                            </Link> */}
                            <Link to='/wallets' className={`navItem  ${toggleState === 2 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(2) }}>
                                <i className="ri-wallet-line navItemIcon"></i>
                                <div className='navItemLabel'>Wallets</div>
                            </Link>
                            <Link to='/assets' className={`navItem  ${toggleState === 3 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(3) }}>
                                <i className="ri-copper-coin-line navItemIcon"></i>
                                <div className='navItemLabel'>Assets</div>
                            </Link>
                        </div>
                        <div className="submenu">
                            <div className="submenuTitle">Transactions</div>
                            <Link to='/transactions' className={`navItem  ${toggleState === 4 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(4) }}>
                                <i className="ri-list-check navItemIcon"></i>
                                <div className='navItemLabel'>View all</div>
                            </Link>
                            <Link to='/proposals' className={`navItem  ${toggleState === 5 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(5) }}>
                                <i className="ri-check-line navItemIcon"></i>
                                <div className='navItemLabel'>Proposals</div>
                            </Link>
                        </div>
                        <div className="comingSoonContainer">
                            <div className="submenuTitle">Accounting</div>
                            <div className="comingSoon">
                                <div className="comingSoonLabel">Coming soon</div>
                            </div>
                        </div>
                        <div className="submenu">
                            <div className="submenuTitle">Address book</div>
                            <Link to='/addresses' className={`navItem  ${toggleState === 6 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(6) }}>
                                <i className="ri-user-line navItemIcon"></i>
                                <div className='navItemLabel'>Addresses</div>
                            </Link>
                            <Link to='/groups' className={`navItem  ${toggleState === 7 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(7) }}>
                                <i className="ri-group-line navItemIcon"></i>
                                <div className='navItemLabel'>Groups</div>
                            </Link>
                        </div>
                    </div>
                }

                {memberMenuToggle ?
                    <div className='memberMenu'>
                        <div className="user">
                            <div className="userTitle">
                                <div className="userAvatar" style={{ background: "#CFE3FD" }}>DK</div>
                                <div className="userNameAccess">
                                    <div className="userName">Delanoy Kunil</div>
                                    <div className="userAccess">Approver</div>
                                </div>
                            </div>
                            <i className="userMenu ri-close-line" onClick={() => memberMenuToggler()}></i>
                        </div>



                        <div className="noteContainer">
                            <div className='note'>
                                <div className='noteTitle'>
                                    Note!
                                </div>
                                <div className='noteDescription'>
                                    Features in this menu is unavailable for this demo.
                                </div>
                            </div>
                        </div>



                        <div className='userList'>
                            <div className='member active'>
                                <div className='avatar'>DK</div>
                                <div className='nameContainer'>
                                    <div className='name'>Delanoy Kunil</div>
                                    <div className='email'>delanoykunil@gmail.com</div>
                                </div>
                                <i className="ri-check-line activeIcon"></i>
                            </div>
                            <div className='member'>
                                <div className='avatar'>DK</div>
                                <div className='nameContainer'>
                                    <div className='name'>Delanoy Kunil</div>
                                    <div className='email'>delanoykunil@gmail.com</div>
                                </div>
                            </div>
                        </div>
                        <div className="accountOption">
                            <div className={`navItems`}>
                                <div className={`navItem`}>
                                    <i className="ri-menu-add-line navItemIcon"></i>
                                    <div className='navItemLabel'>Add account</div>
                                </div>
                                <div className={`navItem`}>
                                    <i className="ri-question-line navItemIcon"></i>
                                    <div className='navItemLabel'>Help and support</div>
                                </div>
                            </div>
                            <button className='textbtn med iconLeft danger'><i className="ri-logout-box-line icon"></i> <div className='label'>Logout</div></button>
                        </div>
                    </div>
                    :
                    <div className="user">
                        <div className="userTitle">
                            <div className="userAvatar" style={{ background: "#CFE3FD" }}>DK</div>
                            <div className="userNameAccess">
                                <div className="userName">Delanoy Kunil</div>
                                <div className="userAccess">Approver</div>
                            </div>

                        </div>
                        <i className="userMenu ri-menu-3-line" onClick={() => memberMenuToggler()}></i>
                    </div>
                }


            </div>

            <div className={`sidenav ${adminSettings ? `sidenavActive` : ''}`}>

                <div className='backContainer'>
                    <button className='textbtn small iconLeft neutral' onClick={() => back()}><i className="ri-arrow-left-s-line icon"></i> <div className='label'>Workspace</div></button>
                </div>

                <div className="navmenu">
                    <div className="submenu">
                        <div className="submenuTitle">Workspace</div>
                        <Link to='/members' className={`navItem  ${toggleState === 6 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(6) }}>
                            <i className="ri-group-2-line navItemIcon"></i>
                            <div className='navItemLabel'>Members</div>
                        </Link>
                        <Link to='/company' className={`navItem  ${toggleState === 7 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(7) }}>
                            <i className="ri-building-3-line navItemIcon"></i>
                            <div className='navItemLabel'>Company</div>
                        </Link>
                    </div>
                    <div className="submenu">
                        <div className="submenuTitle">Notifications</div>
                        <Link to='/emailNotifications' className={`navItem  ${toggleState === 8 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(8) }}>
                            <i className="ri-mail-line navItemIcon"></i>
                            <div className='navItemLabel'>Email</div>
                        </Link>
                        <Link to='/slackNotifications' className={`navItem  ${toggleState === 9 ? 'active' : ''}`} style={{ textDecoration: "none" }} onClick={() => { setTab(9) }}>
                            <i className="ri-slack-line navItemIcon"></i>
                            <div className='navItemLabel'>Slack</div>
                        </Link>
                    </div>
                </div>



            </div>
        </div>
    )
}

export default SideNav
