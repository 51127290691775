import { useState } from 'react';

const CompanyDetails = () => {
    const handleChange = (value) => {
        console.log(value)
    }

    const [companyDropdown, setCompanyDropdown] = useState(false)

    const dropdownToggle = () => {
        setCompanyDropdown(!companyDropdown)
        console.log(companyDropdown)
    }
    return (
        <div className="companyDetailsContainer">
            <div className="companyPageTitle">
                Company
            </div>

            <form className="detailsContainer">
                <div className="textInputContainer">
                    <label className="textLabel">
                        Company name
                    </label>
                    <input className="textInput" type="text" name="name" value={'Acme & Co'} onChange={handleChange} />
                </div>

                <div className="dropdownContainer">
                    <label htmlFor="cars" className="dropdownLabel">Company type</label>
                    <div className={`dropdownTrigger ${companyDropdown ? 'dropdownFocused' : ''}`} onClick={() => dropdownToggle()}><div className='dropdownTriggerLabel'>DAO</div>
                        {companyDropdown ? <i className="ri-arrow-up-s-line dropdownTriggerIcon"></i> : <i className="ri-arrow-down-s-line dropdownTriggerIcon"></i>}
                    </div>
                    <ul className={`dropdownMenu ${companyDropdown ? 'showDropdownMenu' : 'hideDropdownMenu'}`} style={{ zIndex: '1' }}>
                        <li className='dropdownMenuItem'>Crypto Exchange</li>
                        <li className='dropdownMenuItem'>DAO <i className="ri-check-line"></i></li>
                        <li className='dropdownMenuItem'>Venture Capitalist</li>
                        <li className='dropdownMenuItem'>Others</li>
                    </ul>
                </div>

                <div className="textInputContainer">
                    <label className="textLabel">
                        Workspace name
                    </label>
                    <input className="textInput" type="text" name="name" value={'Acme & Co'} disabled />
                    <div className='checkboxContainer'>
                        <i className="ri-checkbox-fill checkbox"></i>
                        <div className='checkboxLabel'>Same as company name</div>
                    </div>
                </div>

                <input className='strokeBtn medStrokeBtn neutralStrokeBtn noIcon' type="submit" value="Save changes" disabled />
            </form>

        </div>
    )
}

export default CompanyDetails
