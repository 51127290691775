

const negPos = (number) => {
    if (Math.sign(number) > 0) {
        return <i className="ri-arrow-up-line success"></i>;
    } else if (Math.sign(number) < 0) {
        return <i className="ri-arrow-down-line negative"></i>;
    }
    return <i className="ri-number-0"></i>;
}

const negPosStyle = (number) => {
    if (Math.sign(number) > 0) {
        return "#069668";

    } else if (Math.sign(number) < 0) {
        return "#963606";
    }
    return "#71717A";
}

const TrackedWallet = ({ trackedWallet, cryptos, toggleUnavailable }) => {

    trackedWallet.forEach(wallet => {
        wallet.USDBalance = 0;
        wallet.percent_change_24h = 0;
        let assetTemp = 0;
        let trendTemp = 0;
        wallet.balance.forEach(asset => {
            cryptos.forEach(crypto => {
                if (asset.assetId === crypto.id) {
                    assetTemp = crypto.quote.USD.price * asset.tokens;
                    trendTemp = crypto.quote.USD.percent_change_24h
                }
            })
            wallet.USDBalance = assetTemp + wallet.USDBalance
            wallet.percent_change_24h = trendTemp + wallet.percent_change_24h
        })
    });


    return (
        < >
            {trackedWallet.map((wallet) => (
                <div className="trackedWallet" key={wallet.id}>
                    <div className="title">
                        <i className="ri-wallet-line titleIcon"></i>
                        <div className="titleLabel">{wallet.name}</div>
                    </div>
                    <div className="attributes">
                        <div className="balanceContainer">
                            <div className="usd">${Math.trunc(wallet.USDBalance)}</div>
                            <div className="cents">{(wallet.USDBalance.toFixed(2)).toString().substring((wallet.USDBalance.toFixed(2)).toString().indexOf("."))}</div>
                        </div>
                        <div className="trend">
                            {negPos(wallet.percent_change_24h)}
                            <div className="trendLabel" style={{ color: negPosStyle(wallet.percent_change_24h) }}>{wallet.percent_change_24h.toFixed(2)}%</div>
                        </div>
                    </div>
                    <div className="actions">
                        <button onClick={() => toggleUnavailable()} className="iconbtn brand">
                            <div className="container">
                                <i className="ri-qr-code-line med"></i>
                            </div>
                        </button>

                        <button onClick={() => toggleUnavailable()} className="iconbtn negative">
                            <div className="container">
                                <i className="ri-link-unlink-m med"></i>
                            </div>
                        </button>
                    </div>
                </div>
            ))}
        </>
    )
}

export default TrackedWallet
