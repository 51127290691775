import Transaction from "./Transaction";
import FixedActions from './FixedActions';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

const Transactions = ({ sideMenuBlur, toggleUnavailable }) => {
    const params = useParams()
    const location = useLocation()

    const [transactions, setTransactions] = useState([])
    const [transactionCount, setTransactionCount] = useState()
    const [transactionPage, setTransactionPage] = useState(0)
    const [transactionsPerPage, setTransactionsPerPage] = useState(25)
    const [lastIndex, setLastIndex] = useState((transactionPage + 1) * transactionsPerPage)
    const [firstIndex, setFirstIndex] = useState(lastIndex - transactionsPerPage + 1)
    const [lastPage, setLastPage] = useState(false)
    const [firstPage, setFirstPage] = useState(true)
    useEffect(() => {
        const getTransactions = async () => {
            const transactionsFromServer = await fetchTransactions()
            setTransactions(transactionsFromServer.transactions);
            setTransactionCount(transactionsFromServer.transactionsCount);
        }
        getTransactions();
    }, [])

    const fetchTransactions = async () => {
        const queryParams = new URLSearchParams(location.search)
        const walletId = queryParams.get('walletId')
        if (walletId) {
            const res = await fetch(`https://cmcserver.onrender.com/transactions/?page=${transactionPage}&walletId=${walletId}`)
            const data = await res.json()
            return data
        }
        const res = await fetch(`https://cmcserver.onrender.com/transactions/?page=${transactionPage}`)
        const data = await res.json()
        return data
    }


    const onTransactionPageIncrease = () => {
        if (lastIndex < transactionCount) {
            setTransactionPage(transactionPage + 1)
            setFirstIndex(firstIndex + transactionsPerPage)
            setLastIndex(lastIndex + transactionsPerPage)
            const fetchTransactionsPage = async () => {
                const queryParams = new URLSearchParams(location.search)
                const singleQuery = queryParams.get('walletId')
                if (singleQuery) {
                    console.log("wallet id: " + params.id)

                    const res = await fetch(`https://cmcserver.onrender.com/transactions/?page=${transactionPage + 1}&walletId=${singleQuery}`)
                    const data = await res.json()
                    return data
                }
                const res = await fetch(`https://cmcserver.onrender.com/transactions/?page=${transactionPage + 1}`)
                const data = await res.json()
                return data
            }
            const getTransactions = async () => {
                const transactionsFromServer = await fetchTransactionsPage()
                setTransactions(transactionsFromServer.transactions);
                setTransactionCount(transactionsFromServer.transactionsCount);
            }
            getTransactions();
            setFirstPage(false)
            if (lastIndex >= (transactionCount - transactionsPerPage)) {
                setLastPage(true)
            } else {
                setLastPage(false)
            }
        }
    }

    const onTransactionPageDecrease = () => {
        if (transactionPage > 0) {
            setTransactionPage(transactionPage - 1)
            setFirstIndex(firstIndex - transactionsPerPage)
            setLastIndex(lastIndex - transactionsPerPage)
            const fetchTransactionsPage = async () => {
                const queryParams = new URLSearchParams(location.search)
                const walletId = queryParams.get('walletId')
                if (walletId) {

                    const res = await fetch(`https://cmcserver.onrender.com/transactions/?page=${transactionPage - 1}&walletId=${walletId}`)
                    const data = await res.json()
                    return data
                }
                const res = await fetch(`https://cmcserver.onrender.com/transactions/?page=${transactionPage - 1}`)
                const data = await res.json()
                return data
            }
            const getTransactions = async () => {
                const transactionsFromServer = await fetchTransactionsPage()
                setTransactions(transactionsFromServer.transactions);
                setTransactionCount(transactionsFromServer.transactionsCount);
            }
            getTransactions();
            setLastPage(false)
            if (transactionPage === 1) {
                setFirstPage(true)
            } else {
                setFirstPage(false)
            }
        }
    }

    const handleTransactionClick = transactionId => {
        let newTransactions = []
        newTransactions = transactions.map(transaction =>
            transaction.id === transactionId
                ? {
                    ...transaction,
                    expanded: !transaction.expanded
                }
                : {
                    ...transaction,
                    expanded: false
                }
        )
        setTransactions(newTransactions)
    };

    return (

        <div className={`transactionsContainer ${sideMenuBlur ? 'blur' : ''}`}>
            <FixedActions toggleUnavailable={toggleUnavailable} />
            <div className='pageTitleContainer'>
                <div className="pageTitleSearch">
                    <div className='pageTitle'>All transaction</div>
                    <div className="textInputContainer">
                        <i className="textInputIcon ri-search-2-line"></i>
                        <input className="textInput search" type="text" name="name" placeholder='Search hash' onClick={() => toggleUnavailable()} />
                    </div>
                </div>
            </div>
            <div className="filtersPaginationTransactions">
                <div className="filtersPagination">
                    <div className="filter">
                        <div className="filterItem" onClick={() => toggleUnavailable()}>
                            <div className="filterItemLabel">All filters</div>
                        </div>
                    </div>
                    <div className="pagination">
                        {transactionCount > transactionsPerPage ? `${firstIndex} - ${lastPage ? transactionCount : lastIndex} of ` : ``}{transactionCount} transactions
                        {transactionCount > transactionsPerPage ?
                            <div className="actions">
                                <button onClick={() => { onTransactionPageDecrease() }} className={`iconbtn ${firstPage ? 'disabled' : ''}`}>
                                    <div className="container">
                                        <i className="ri-arrow-left-s-line sml"></i>
                                    </div>
                                </button>
                                <button onClick={() => { onTransactionPageIncrease() }} className={`iconbtn ${lastPage ? 'disabled' : ''}`}>
                                    <div className="container">
                                        <i className="ri-arrow-right-s-line sml"></i>
                                    </div>
                                </button>
                            </div>
                            : ``}
                    </div>
                </div>
                <div className="transactionsList">
                    <div className="transactionsHeader">
                        <div className="typeTime">Type & Time</div>
                        <div className="walletNetwork">Wallet & Network</div>
                        <div className="toFrom">To/From</div>
                        <div className="in">In</div>
                        <div className="out">Out</div>
                        <div className="classification">Classification</div>
                    </div>
                    <Transaction transactions={transactions} handleClick={handleTransactionClick} />
                </div>
            </div>
            <div className="footerPagination">
                <div className="pagination">
                    {transactionCount > transactionsPerPage ? `${firstIndex} - ${lastIndex} of ` : ``}{transactionCount} transactions
                    {transactionCount > transactionsPerPage ?
                        <div className="actions">
                            <button onClick={() => { onTransactionPageDecrease() }} className={`iconbtn ${firstPage ? 'disabled' : ''}`}>
                                <div className="container">
                                    <i className="ri-arrow-left-s-line sml"></i>
                                </div>
                            </button>
                            <button onClick={() => { onTransactionPageIncrease() }} className={`iconbtn ${lastPage ? 'disabled' : ''}`}>
                                <div className="container">
                                    <i className="ri-arrow-right-s-line sml"></i>
                                </div>
                            </button>
                        </div>
                        : ``}
                </div>
            </div>
        </div>
        // <>





        //     <div className="transactionsContainer">
        //         <div className="transactionsTitleSearch">
        //             <div className="transactionsTitle">All transactions</div>
        //             <div className="searchBar">
        //                 <i className="ri-search-2-line inputIcon"></i>
        //                 <div className="placeholder">Search hash</div>
        //             </div>
        //         </div>
        // {transactions.map((transaction) => (
        //     <Transaction key={transaction.id} transaction={transaction} />
        // ))}
        //     </div>
        // </>
    )
}

export default Transactions
