import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Assets from './components/Assets';
import Transactions from './components/Transactions';
import Proposals from './components/Proposals';
import Addresses from './components/Addresses';
import Groups from './components/Groups';
import Wallets from './components/Wallets';
import SideNav from './components/SideNav';
import WalletDetails from './components/WalletDetails';
import Members from './components/Members';
import CompanyDetails from './components/CompanyDetails';
import EmailNotifications from './components/EmailNotifications';
import SlackNotifications from './components/SlackNotifications';
import Unavailable from './components/Unavailable';
import FixedActions from './components/FixedActions';

function App() {
  // const params = useParams()

  const [workspaces, setWorkspaces] = useState([])

  useEffect(() => {
    const getWorkspaces = async () => {
      const workspacesFromServer = await fetchWorkspaces()
      setWorkspaces(workspacesFromServer);
    }
    getWorkspaces();
  }, [])

  const fetchWorkspaces = async () => {
    const res = await fetch('https://cmcserver.onrender.com/workspaces')
    const data = await res.json()
    return data
  }

  const [wallets, setWallets] = useState([])

  useEffect(() => {
    const getWallets = async () => {
      const walletsFromServer = await fetchWallets()
      setWallets(walletsFromServer);
    }
    getWallets();
  }, [])

  const fetchWallets = async () => {
    const res = await fetch('https://cmcserver.onrender.com/wallets')
    const data = await res.json()
    return data
  }


  const addWallet = async (wallet) => {
    const res = await fetch("https://cmcserver.onrender.com/wallets", {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(wallet)
    })

    const data = await res.json()

    setWallets([...wallets, data])
  }

  const [members, setMembers] = useState([])

  useEffect(() => {
    const getMembers = async () => {
      const membersFromServer = await fetchMembers()
      setMembers(membersFromServer);
    }
    getMembers();
  }, [])

  const fetchMembers = async () => {
    const res = await fetch(`https://cmcserver.onrender.com/members/`)
    const data = await res.json()
    return data
  }

  const [cryptos, setCryptos] = useState([])

  useEffect(() => {
    const getCryptos = async () => {
      const cryptosFromServer = await fetchCryptos()
      setCryptos(cryptosFromServer);
    }
    getCryptos();
  }, [])

  const fetchCryptos = async () => {
    const res = await fetch('https://cmcserver.onrender.com/cryptocurrencies')
    const data = await res.json()
    return data
  }

  const [workspaceToggle, setWorkspaceToggle] = useState(false)
  const [adminSettings, setAdminSettings] = useState(false)
  const [memberMenuToggle, setMmemberMenuToggle] = useState(false)
  const [sideMenuBlur, setSideMenuBlur] = useState(false)

  const workspaceToggler = () => {
    setWorkspaceToggle(!workspaceToggle)
    setSideMenuBlur(!sideMenuBlur)
  }

  const memberMenuToggler = () => {
    setMmemberMenuToggle(!memberMenuToggle)
  }

  const adminToggler = () => {
    setAdminSettings(!adminSettings)
  }
  const backToWorkspace = () => {
    setAdminSettings(!adminSettings)
  }

  const [showUnavailable, setShowUnavailable] = useState(false)
  const toggleUnavailable = () => {
    setShowUnavailable(!showUnavailable)
  }

  return (
    <Router>
      <div className="mainContainer">
        {showUnavailable && <Unavailable toggleUnavailable={toggleUnavailable} />}
        <SideNav workspaces={workspaces} members={members} workspaceToggle={workspaceToggle} workspaceToggler={workspaceToggler} memberMenuToggle={memberMenuToggle} memberMenuToggler={memberMenuToggler} adminSettings={adminSettings} adminToggler={adminToggler} backToWorkspace={backToWorkspace} />
        <div className='viewContainer'>
          <Routes>
            <Route path="/" exact element={<Wallets wallets={wallets} cryptos={cryptos} sideMenuBlur={sideMenuBlur} />} />
            <Route path="/wallets" exact element={<Wallets wallets={wallets} cryptos={cryptos} sideMenuBlur={sideMenuBlur} toggleUnavailable={toggleUnavailable} />} />
            <Route path={`/wallet/:id`} exact element={<WalletDetails sideMenuBlur={sideMenuBlur} toggleUnavailable={toggleUnavailable} />} />
            <Route path="/assets" exact element={<Assets sideMenuBlur={sideMenuBlur} toggleUnavailable={toggleUnavailable} />} />
            <Route path="/transactions" exact element={<Transactions sideMenuBlur={sideMenuBlur} toggleUnavailable={toggleUnavailable} />} />
            <Route path={`/proposals`} exact element={<Proposals sideMenuBlur={sideMenuBlur} toggleUnavailable={toggleUnavailable} />} />
            <Route path={`/addresses`} exact element={<Addresses sideMenuBlur={sideMenuBlur} toggleUnavailable={toggleUnavailable} />} />
            <Route path={`/groups`} exact element={<Groups sideMenuBlur={sideMenuBlur} toggleUnavailable={toggleUnavailable} />} />
            <Route path="/members" exact element={<Members workspaces={workspaces} members={members} wallets={wallets} />} />
            <Route path="/company" exact element={<CompanyDetails />} />
            <Route path="/emailNotifications" exact element={<EmailNotifications />} />
            <Route path="/slackNotifications" exact element={<SlackNotifications />} />
          </Routes>
        </div>

      </div>
    </Router>
  );
}

export default App;
