import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const ChromeStyler = React.lazy(() => import('./components/ChromeStyle'));
const NonChromeStyler = React.lazy(() => import('./components/NonChrome'));

const ThemeSelector = ({ children }) => {
  function whichBrowser() {
    if (isFirefox()) {
      return "Firefox";
    } else if (isEdge()) {
      return "Edge";
    } else if (isIE()) {
      return "Internet Explorer";
    } else if (isOpera()) {
      return "Opera";
    } else if (isVivaldi()) {
      return "Vivalid";
    } else if (isChrome()) {
      return "Chrome";
    } else if (isSafari()) {
      return "Safari";
    } else {
      return "Unknown";
    }
  }

  function agentHas(keyword) {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
  }

  function isIE() {
    return !!document.documentMode;
  }

  function isSafari() {
    return (!!window.ApplePaySetupFeature || !!window.safari) && agentHas("Safari") && !agentHas("Chrome") && !agentHas("CriOS");
  }

  function isChrome() {
    return agentHas("CriOS") || agentHas("Chrome") || !!window.chrome;
  }

  function isFirefox() {
    return agentHas("Firefox") || agentHas("FxiOS") || agentHas("Focus");
  }

  function isEdge() {
    return agentHas("Edg");
  }

  function isOpera() {
    return agentHas("OPR");
  }

  function isVivaldi() {
    return agentHas("Vivaldi");
  }
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(whichBrowser() === "Chrome") ? <ChromeStyler /> : <NonChromeStyler />}
      </React.Suspense>
      {children}
    </>
  )
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeSelector>
    <App />
    </ThemeSelector>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
