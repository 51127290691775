import safeIcon from "./../icons/Safe.svg"
import optimismIcon from "./../icons/blockchain/OPTIMISM.svg";
import BSC from "./../icons/blockchain/BSC.svg";
import ethereumIcon from "./../icons/blockchain/Ethereum.svg";
import polygonIcon from "./../icons/blockchain/Polygon.svg";
import solanaIcon from "./../icons/blockchain/SOL.svg";

const negPos = (number) => {
    if (Math.sign(number) > 0) {
        return <i className="ri-arrow-up-line success"></i>;
    } else if (Math.sign(number) < 0) {
        return <i className="ri-arrow-down-line negative"></i>;
    }
    return <i className="ri-number-0"></i>;
}

const negPosStyle = (number) => {
    if (Math.sign(number) > 0) {
        return "#069668";

    } else if (Math.sign(number) < 0) {
        return "#963606";
    }
    return "#71717A";
}

const blockchainIcon = (blockchain) => {
    if (String(blockchain).match("Optimism")) {
        return <img src={optimismIcon} style={{fontSize: "16px", position: "absolute", left: "16px", top: "16px", color: "#34785E"}} />;
    }
    else if (String(blockchain).match("BSC")) {
        return <img src={BSC} style={{fontSize: "16px", position: "absolute", left: "16px", top: "16px", color: "#34785E"}} />;
    }
    else if (String(blockchain).match("Ethereum")) {
        return <img src={ethereumIcon} style={{fontSize: "16px", position: "absolute", left: "16px", top: "16px", color: "#34785E"}} />;
    }
    else if (String(blockchain).match("Gnosis chain")) {
        return <i className="cf cf-gno" style={{fontSize: "16px", position: "absolute", left: "16px", top: "16px", color: "#34785E"}}></i>;
    }
    else if (String(blockchain).match("Polygon")) {
        return <img src={polygonIcon} style={{fontSize: "16px", position: "absolute", left: "16px", top: "16px", color: "#34785E"}} />;
    }
    else if (String(blockchain).match("Solana")) {
        return <img src={solanaIcon} style={{fontSize: "16px", position: "absolute", left: "16px", top: "16px", color: "#34785E"}} />;
    }
}

const GnosisSafe = ({ gnosisSafe, cryptos, handleClick }) => {

    gnosisSafe.forEach(wallet => {
        wallet.USDBalance = 0;
        wallet.percent_change_24h = 0;
        let assetTemp = 0;
        let trendTemp = 0;
        wallet.balance.forEach(asset => {
            cryptos.forEach(crypto => {
                if (asset.assetId === crypto.id) {
                    assetTemp = crypto.quote.USD.price * asset.tokens;
                    trendTemp = crypto.quote.USD.percent_change_24h
                }
            })
            wallet.USDBalance = assetTemp + wallet.USDBalance
            wallet.percent_change_24h = trendTemp + wallet.percent_change_24h
        })
    });

    return (
        <>
            {
                gnosisSafe.map((wallet) => (
                    <div className="trackedWallet gnosisSafe" key={wallet.id} onClick={() => { handleClick(wallet.id) }}>
                        <div className="title">
                            <div className="titleIconContainer">
                                {
                                    blockchainIcon(wallet.blockchain)
                                }
                                <img src={safeIcon} className="titleIcon" />
                            </div>
                            <div className="titleLabel">{wallet.name}</div>
                        </div>
                        <div className="attributes">
                            <div className="balanceContainer">
                                <div className="usd">${Math.trunc(wallet.USDBalance)}</div>
                                <div className="cents">{(wallet.USDBalance.toFixed(2)).toString().substring((wallet.USDBalance.toFixed(2)).toString().indexOf("."))}</div>
                            </div>
                            <div className="trend">
                                {negPos(wallet.percent_change_24h)}
                                <div className="trendLabel" style={{ color: negPosStyle(wallet.percent_change_24h) }}>{wallet.percent_change_24h.toFixed(2)}%</div>
                            </div>
                        </div>
                        <div className="actionsGnosis">
                            <button onClick={() => { console.log("clicked") }} className="iconbtn brand">
                                <div className="container">
                                    <i className="ri-qr-code-line med brand"></i>
                                </div>
                            </button>
                            <button onClick={() => { console.log("clicked") }} className="iconbtn brand">
                                <div className="container">
                                    <i className="ri-send-plane-line med brand"></i>
                                </div>
                            </button>
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default GnosisSafe
