

const Member = ({ member }) => {
    const determineStatus = (status) => {
        if (status === "active") {
            return "#069668"
        } else if (status === "suspended") {
            return "#963606"
        } else if (status === "invited") {
            return "#A1A1AD"
        } else if (status === "authorisation reset") {
            return "#FBBE24"
        }
    }

    const determineActions = (status) => {
        if (status === "active") {
            return <div className="actions">
                <button className='textbtn small danger'><div className='label'>Suspend</div></button>
                <button className='textbtn small neutral'><div className='label'>Reset Authorization</div></button>
            </div>
        }
        if (status === "authorisation reset") {
            return <div className="actions">
            </div>
        }
        if (status === "suspended") {
            return <div className="actions" style={{ justifyContent: "flex-end" }}>
                <button className='textbtn small neutral'><div className='label'>Resume access</div></button>
            </div>
        }
        if (status === "invited") {
            return <div className="actions">
                <button className='textbtn small danger'><div className='label'>Withdraw</div></button>
                <button className='textbtn small neutral'><div className='label'>Resend</div></button>
            </div>
        }
    }

    return (
        <div className="member">
            <div className="nameContainer">
                {member.status === "invited" ?
                    <i className="ri-mail-check-line invitedIcon"></i>
                    :
                    <div className="avatar" style={{ backgroundColor: member.colour }}>
                        {member.name.split(' ')
                            .map(word => word.charAt(0))
                            .join('')
                            .slice(0, 2)
                        }
                    </div>
                }
                {member.status === "invited" ?
                    <div className="nameEmail">
                        <div className="email invited">{(member.email).slice(0, 2)}...{(member.email).slice(member.email.indexOf('@'))}</div>
                    </div>
                    :
                    <div className="nameEmail">
                        <div className="name">{member.name}</div>
                        <div className="email">{(member.email).slice(0, 2)}...{(member.email).slice(member.email.indexOf('@'))}</div>
                    </div>
                }
            </div>
            <div className="status">
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: determineStatus(member.status) }}></div>
                </div>
                <div className="statusLabel">{member.status}</div>
            </div>
            <div className="typeWallets">
                <div className="type">
                    {member.role}
                </div>
                {member.role === "viewer" ? '' :
                    <div className="wallets">
                        {member.walletTypeCount.hasOwnProperty('1') && member.walletTypeCount.hasOwnProperty('2') ? <>
                            <div className="wallet">{member.walletTypeCount.hasOwnProperty('1') ? `${member.walletTypeCount['1']} MPC Wallets` : ''}</div>
                            <div className="wallet">{member.walletTypeCount.hasOwnProperty('2') ? `, ${member.walletTypeCount['2']} Safes` : ''}</div>
                        </> : <>
                            <div className="wallet">{member.walletTypeCount.hasOwnProperty('1') ? `${member.walletTypeCount['1']} MPC Wallets` : ''}</div>
                            <div className="wallet">{member.walletTypeCount.hasOwnProperty('2') ? `${member.walletTypeCount['2']} Safes` : ''}</div>
                        </>}
                    </div>
                }
            </div>
            {determineActions(member.status)}
        </div>
    )
}

export default Member
