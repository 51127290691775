import { useEffect } from 'react';

const Unavailable = ({toggleUnavailable}) => {
    useEffect(() => {
        const close = (e) => {
          if(e.keyCode === 27){
            toggleUnavailable()
          }
        }
        window.addEventListener('keydown', close)
      return () => window.removeEventListener('keydown', close)
    },[])

    return (
        <div className="overlay" >
            <div className="dialogBackground">
                <div className="dialog">
                    <div className="titleContainer">
                        <div className="title">
                        Feature unavailable.
                        </div>
                        <div className="subtitle">
                        This feature will be soon available in this demo.
                        </div>
                    </div>
                    <div className="actions">
                        <button className='filledbtn largeFilledBtn neutralFilledBtn' onClick={toggleUnavailable} ><div className='label'>Dismiss [Esc]</div></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unavailable
