import { useState, useEffect } from 'react';
import Moment from 'react-moment';

import safeIcon from "./../icons/Safe.svg";

import ring from "./../icons/ring.svg";

import optimismIcon from "./../icons/blockchain/OPTIMISM.svg";
import BSC from "./../icons/blockchain/BSC.svg";
import Ethereum from "./../icons/blockchain/Ethereum.svg";
import gnosisChainIcon from "./../icons/blockchain/Gnosis.svg";
import polygonIcon from "./../icons/blockchain/Polygon.svg";
import solanaIcon from "./../icons/blockchain/SOL.svg";

const Proposal = ({ proposals, toggleUnavailable }) => {
    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        const getAddresses = async () => {
            const addressesFromServer = await fetchAddresses()
            setAddresses(addressesFromServer);
        }
        getAddresses();
    }, [])

    const fetchAddresses = async () => {
        const res = await fetch('https://cmcserver.onrender.com/addresses/')
        const data = await res.json()
        return data
    }

    const blockchainIcon = (chainName) => {
        if (Array.isArray(chainName)) {
            return [Ethereum, polygonIcon, BSC];
        }
        if (chainName.match("Optimism")) {
            return optimismIcon;
        }
        else if (chainName.match("BSC")) {
            return BSC;
        }
        else if (chainName.match("Ethereum")) {
            return Ethereum;
        }
        else if (chainName.match("Gnosis Chain")) {
            return gnosisChainIcon;
        }
        else if (chainName.match("Polygon")) {
            return polygonIcon;
        }
        else if (chainName.match("Solana")) {
            return solanaIcon;
        }
    }

    const determindWalletType = (walletType) => {
        if (walletType === 1) {
            return <i className="ri-wallet-3-line sourceIcon"></i>
        }
        if (walletType === 2) {
            return <img src={safeIcon} className="sourceIcon" />
        }
        if (walletType === 3) {

            return <i className="ri-wallet-line sourceIcon"></i>
        }
    }

    const deteremineMultirecipients = (multiRecipient, recipents) => {
        let recipientName = ""
        if (multiRecipient) {
            return recipents.length + " recipients";
        } else if (typeof recipents === "string") {
            return `${recipents.slice(0, 5)}...${recipents.slice(-5)}`;
        } else if (typeof recipents === "object") {
            addresses.forEach(address => {
                if (recipents.addressId === address.id) {
                    recipientName = address.name
                }
            })
            return recipientName;
        }
        return null;
    }

    const deteremineRecipientIcon = (multiRecipient, recipents) => {
        if (multiRecipient) {
            return <i className="ri-group-line destinationIcon"></i>;
        } else if (typeof recipents === "string") {
            return <i className="ri-hashtag destinationIcon"></i>
        } else if (typeof recipents === "object") {
            return <i className="ri-user-line destinationIcon"></i>
        }
        return null;
    }

    const determineStatus = (status) => {
        if (status === "executing") {
            return <div className="status">
                {/* <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#A1A1AD" }}></div>
                </div> */}

                <div className="Animated_status_ring">
                    <img src={ring} className="VectorRing" />
                </div>

                <div className="statusLabel">Executing</div>
            </div>
        } else if (status === "execution failed") {
            return <div className="status">
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#963606" }}></div>
                </div>
                <div className="statusLabel">Failed</div>
                <button className='textbtn small brand'><div className='label'>Retry</div></button>
            </div>
        } else if (status === "execution ready") {
            return <div className="status">
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#069668" }}></div>
                </div>
                <div className="statusLabel">Ready</div>
                <button className='textbtn small brand'><div className='label'>Execute</div></button>
            </div>
        } else if (status === "awaiting nonce") {
            return <div className="status">
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#A1A1AD" }}></div>
                </div>
                <div className="statusLabel">Awaiting nonce</div>
            </div>
        } else if (status === "no action") {
            return <div className="status">
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#A1A1AD" }}></div>
                </div>
                <div className="statusLabel">No action</div>
            </div>
        } else if (status === "actioned") {
            return <div className="status">
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#069668" }}></div>
                </div>
                <div className="statusLabel">Actioned</div>
            </div>
        } else if (status === "approval required") {
            return <div className="status">
                <div className="Animated_status_dot">
                    <div className="Vector1"></div>
                    <div className="Vector2"></div>
                </div>
                <div className="statusLabel" style={{ fontWeight: 800 }}>Action required</div>
            </div>
        } else if (status === "discarded") {
            return <div className="status" style={{ position: "relative" }}>
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#963606" }}></div>
                </div>
                <div className="statusLabel">Discarded</div>
                <button className='textbtn small brand' style={{ position: "absolute", right: 0 }}><div className='label'>Retry</div></button>
            </div>
        } else if (status === "rejected") {
            return <div className="status" style={{ position: "relative" }}>
                <div className="statusIcon">
                    <div className="statusDot" style={{ backgroundColor: "#DFC01A" }}></div>
                </div>
                <div className="statusLabel">Rejected</div>
                <button className='textbtn small brand' style={{ position: "absolute", right: 0 }}><div className='label'>Retry</div></button>
            </div>
        }
        return null;
    }


    const determineHoverAction = (status) => {
        if (status === "approval required") {
            return <div className="onHoverIndication">
                <div className="onHoverIndicationLabel">View and review</div>
                <i className="ri-arrow-right-up-line onHoverIndicationIcon"></i>
            </div>
        } else {
            return <div className="onHoverIndication">
                <div className="onHoverIndicationLabel">View</div>
                <i className="ri-arrow-right-up-line onHoverIndicationIcon"></i>
            </div>
        }
    }

    return (
        <>
            {proposals.map((proposal) => (


                <div className={`proposal ${proposal.titleTransaction ? 'titleTransaction' : ''}`} key={proposal.id} onClick={() => toggleUnavailable()}>
                    <div className="initiation">
                        <div className="timeContainer">
                            {proposal.moreThan3 ?
                                <Moment className="time" date={proposal.timestamp} fromNow /> :
                                <Moment className="time" date={proposal.timestamp} format='MMM Do YYYY' />
                            }
                            <Moment className="hoverTime" date={proposal.timestamp} format='MMM Do YYYY, HH:mm' />
                        </div>


                        <div className="initiator">
                            <div className="initiatorAvatar" style={{ background: proposal.initiatorColour }}>
                                <div className="initials">{proposal.initiator.split(' ')
                                    .map(word => word.charAt(0))
                                    .join('')
                                    .slice(0, 2)
                                }</div>
                            </div>
                            <div className="initiatorName">{proposal.initiator}</div>
                        </div>
                    </div>

                    <div className="source">
                        <div className="sourceIconsContainer">
                            {determindWalletType(proposal.walletType)}
                            {proposal.blockchain === "Gnosis chain" ?
                                <i className="cf cf-gno" style={{
                                    fontSize: "16px", position: "absolute",
                                    left: "10px",
                                    bottom: "10px",
                                    color: "#34785E",
                                    borderRadius: "100%",
                                    backgroundColor: "white"
                                }}></i>
                                :
                                <img src={blockchainIcon(proposal.blockchain)} className="networkIcon" />
                            }
                        </div>

                        <div className="sourceName">{proposal.wallet.charAt(0).toUpperCase() + proposal.wallet.slice(1)}</div>
                        <i className="ri-arrow-right-line sourceArrow"></i>
                    </div>

                    <div className="destination">
                        {deteremineRecipientIcon(proposal.multiRecipient, proposal.recipents)}
                        <div className="destinationLabel">
                            {deteremineMultirecipients(proposal.multiRecipient, proposal.recipents)}
                        </div>
                    </div>

                    <div className="amount">
                        <div className="tokenValue">{proposal.outTokens} {proposal.outTokenSymbol}</div>
                        <div className="usdContainer">
                            <div className="negative">-</div>
                            <div className="usd">${Math.trunc(proposal.outTokens * proposal.outUSDPrice)}</div>
                            <div className="cents">{((proposal.outTokens * proposal.outUSDPrice).toFixed(2)).toString().substring(((proposal.outTokens * proposal.outUSDPrice).toFixed(2)).toString().indexOf("."))}</div>
                        </div>
                    </div>



                    <div className="classification">
                        <div className="classificationLabel" style={{ textOverflow: "ellipsis" }}>{proposal.classification}</div>
                        <i className="ri-arrow-down-s-line classificationDropdown"></i>
                    </div>


                    {determineStatus(proposal.status)}
                    {determineHoverAction(proposal.status)}

                </div>
            ))}

        </>

    )
}

export default Proposal
