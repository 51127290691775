import { useState, useEffect } from 'react';
import Group from "./Group"
import FixedActions from './FixedActions';

const Groups = ({ sideMenuBlur, toggleUnavailable }) => {
    const [groups, setGroups] = useState([])

    useEffect(() => {
        const getGroups = async () => {
            const groupsFromServer = await fetchGroups()
            setGroups(groupsFromServer);
        }
        getGroups();
    }, [])

    const fetchGroups = async () => {
        const res = await fetch('https://cmcserver.onrender.com/groups/')
        const data = await res.json()
        return data
    }

    return (
        <div className={`groupsContainer ${sideMenuBlur ? 'blur' : ''}`}>
            <FixedActions toggleUnavailable={toggleUnavailable} />
            <div className='pageTitleContainer'>
                <div className='pageTitle'>Groups</div>
                <button className='filledbtn largeFilledBtn neutralFilledBtn' onClick={() => toggleUnavailable()}><div className='label'>Create Group</div></button>
            </div>
            <div className="paginationGroupList">
                <div className="pagination">
                    {groups.length} groups
                </div>
                <div className="groupList">
                    <Group groups={groups} toggleUnavailable={toggleUnavailable} />
                </div>
                <div className="footerPagination">
                    <div className="pagination">
                        {groups.length} groups
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Groups
