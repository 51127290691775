

const FixedActivities = ({toggleUnavailable}) => {
  return (
    <div className="actionContainers">
        <button className='strokeBtn largeStrokeBtn neutralStrokeBtn onlyIcon' onClick={() => toggleUnavailable()}><i className="ri-flashlight-line"></i></button>
    </div>
  )
}

export default FixedActivities
